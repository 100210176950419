<template>
  <div class="fold-wrap" @click.stop="$emit('click')">
    <div
      v-if="tranType==='aside'"
      :style="`transform:rotate(${expandSide?'0deg':'180deg'})`"
    >
      <div class="triangle-bottomright"></div>
      <div class="btn-fold">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="triangle-topright"></div>
    </div>

    <div
      v-else
      class="warp-up"
      :style="`transform:rotate(${expandSide?'180deg':'0deg'})`"
    >
      <div class="triangle-bottomright"></div>
      <div class="btn-fold-up">
        <i class="el-icon-arrow-up"></i>
      </div>
      <div class="triangle-topright"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoldButton",
  created() { },
  data() {
    return {};
  },
  props: {
    expandSide: {
      type: Boolean,
      default: true,
    },

    tranType: { //类型 aside：左右 upDown:上下
      type: String,
      default: 'aside',
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.fold-wrap {
  display: flex;
  align-items: center;
  .btn-unfold {
    width: 10px;
    height: 60px;
    background: #a8b2b2;
    color: #fff;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
  .btn-fold {
    width: 10px;
    height: 60px;
    background: #a8b2b2;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .warp-up {
    display: inline-flex;
    width: 60px;
    height: 10px;
    margin: auto;
    .btn-fold-up {
      width: 60px;
      height: 10px;
      background: #a8b2b2;
      color: #fff;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
    .triangle-topright {
      transform: rotate(180deg);
    }
  }
  .triangle-topleft {
    width: 0;
    height: 0;
    border-top: 10px solid #a8b2b2;
    border-right: 10px solid transparent;
  }
  .triangle-topright {
    width: 0;
    height: 0;
    border-top: 10px solid #a8b2b2;
    border-left: 10px solid transparent;
  }
  .triangle-bottomleft {
    width: 0;
    height: 0;
    border-bottom: 10px solid #a8b2b2;
    border-right: 10px solid transparent;
  }
  .triangle-bottomright {
    width: 0;
    height: 0;
    border-bottom: 10px solid #a8b2b2;
    border-left: 10px solid transparent;
  }
}
</style>
