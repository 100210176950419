<template>
  <div v-if="modal"
    class="modal-cargroup-info-wrapper">
    <el-dialog :visible="modal"
      width="900px"
      @close="onModalChange"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close>
      <div slot="title"
        class="header-title">
        <span class="visible_name">编辑车组资料</span>
        <span class="tip">注：{{isAdminUser?'不选择上级车组，该车组将添加到车组管理下':'该车组已是最上级车组，不能修改它的上级车组'}}</span>
      </div>
      <div class="cargroup-info-content">
        <p class="info_name">基本信息</p>
        <div class="item">
          <span class="first-item required">车组名称</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.groupName"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span class="first-item">上级车组</span>
          <div class="last-item car-group-dropdown">
            <SelectGroupTree singleGroup :multipleProp="{ noGroup: true }"
              placeholder="选择上级车组"
              :inputVehicle="false"
              :clearable="superClearable"
              @on-select="onSelect"
              @select-group="Confirm"
              @clear="clearClick"
              :selecteds="selecteds" />
          </div>
        </div>

        <div class="item">
          <span>车组类型</span>
          <div class="last-item grouptype-item">
            <el-select style="width:100%"
              v-model="carGroupInfo.groupAttribute"
              clearable
              placeholder="请选择">
              <el-option label="公司"
                :value="1"></el-option>
              <el-option label="车队"
                :value="2"></el-option>
              <el-option label="企业 "
                :value="15"></el-option>
              <el-option label="其他 "
                :value="14"></el-option>
            </el-select>
          </div>
        </div>
        <p class="info_name">车辆资料</p>
        <div class="item">
          <span>联系方式</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.phone"
              :maxlength="15" />
          </div>
        </div>
        <div class="item"
          v-if="showCompanyName">
          <span>企业类型</span>
          <div class="last-item">
            <el-input disabled
              v-model.trim="carGroupInfo.companytype"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>联系人</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.contacts"
              :maxlength="19"
              placeholder="请输入联系人" />
          </div>
        </div>
        <div class="item">
          <span>所属区域</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.area"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>经营范围</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.scope"
              :maxlength="19" />
          </div>
        </div>

        <div class="item">
          <span>道路运输许可证</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.transport"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>核发机关</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.authority"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>法定代表人</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.representative"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>联系地址</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.readdress"
              :maxlength="19" />
          </div>
        </div>
        <div class="item">
          <span>联系电话</span>
          <div class="last-item">
            <el-input v-model.trim="carGroupInfo.rephone"
              :maxlength="13" />
          </div>
        </div>
        <div class="item"
          style="height: 100px;">
          <span>备注</span>
          <div class="last-item">
            <el-input type="textarea"
              style="resize:none;"
              v-model.trim="carGroupInfo.remark"
              :rows="3"
              :maxlength="500"
              resize="none"
              :autosize="{ minRows: 5, maxRows: 5}"
              placeholder="请输入备注" />
          </div>
        </div>
        <!-- <div v-if="isAdminUser" class="item red-color">
          <div>注：</div>
          <div class="last-item">不选择上级车组，该车组将添加到车组管理下</div>
        </div>
        <div
          v-if="!isAdminUser && groupId !== -1 && isRootGroup"
          class="item red-color"
        >
          <div>注：</div>
          <div class="last-item">该车组已是最上级车组，不能修改它的上级车组</div>
        </div>-->
        <!-- <div class="item-cell">
          <el-collapse v-model="activeNames">
            <el-collapse-item title="附件"
              name="1">
              <div class="item-cell__photos">
                <SelectAvatar @on-change="onSelectPhoto"
                @groupDelete="groupDelete"
                  v-for="(item,index) in groupPhotos"
                  :key="index"
                  :extra="item"
                  :src="item.path"
                  :avatarIndex="index"
                  :placeholder="item.type"></SelectAvatar>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div> -->
      </div>
      <span slot="footer"
        class="dialog-footer">
        <el-button size="mini"
          type="primary"
          @click="onSaveData">保存</el-button>
        <el-button size="mini"
          @click="onModalChange(false)">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import InputQuery from "@/components/SearchVehicleGroup";//1
import SelectGroupPopover from "@/components/SelectGroupPopover";//1
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';

import SelectAvatar from '@/components/SelectAvatar';

import {
  addVehGroup,
  updateVehicleGroup,
} from "@/api/getManagementData";

import {
  groupGetphotos,
  groupUploadPhotos,
  groupUpdatephotos,
} from "@/api/getMuckTruck-api";

export default {
  name: "ModalCarGroupInfo",
  components: {
    InputQuery,
    SelectAvatar,
    SelectGroupPopover,
    SelectGroupTree
  },
  model: {
    prop: "modal",
    event: "change",
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: 0,
      required: false,
    },
    groupId: {
      //-1: 添加新车组，>-1: 修改车组
      type: Number,
      default: -1,
    },
    showParentGroup: { // 是否显示上级车组 
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      parentStr: "",
      carGroupInfo: this.initCarGroup(),
      isRootGroup: true, //是否是根车组
      showCompanyName: false,
      visibleModal: false,
      groupPhotos: [],
      activeNames: [" "],
      selecteds: [],
      defaultGroup: false,
      concact: "",
      concactNumber: ""
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapGetters(["realGroups"]),
    isAdminUser() {
      //是否是admin用户
      return this.userInfo.userId === 1;
    },
    /**
     * 上级车组是否可清空, 只要总账号且新增车组的清空下才可清空
     */
    superClearable() {
      const { isAdminUser, groupId } = this;
      // 新增
      const isAdd = groupId === -1;
      return isAdminUser && isAdd;
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.carGroupInfo = this.initCarGroup();
          this.parentStr = this.parentName;
          this.isRootGroup = false;
          this.selecteds = [];
          this.groupPhotos = this.initGroupPhotos();
        }

        if (val && this.groupId !== -1) {
          this.getGroupPhotos();
        } else {
          this.carGroupInfo.parentId = this.parentId;
          this.carGroupInfo.parentName = this.parentName;
        }
      },
    },
    selecteds: {
      handler(val) {
        if (val.length) {
          this.carGroupInfo.parentName = this.selecteds[0].label;
          this.carGroupInfo.parentId = this.selecteds[0].id;
        }
      }
    },

    "carGroupInfo.groupType": {
      handler: function (val) {
        if (val === "渣土车") {
          this.showCompanyName = true;
          this.carGroupInfo.companytype = '渣土公司';
        } else {
          this.showCompanyName = false;
        }
      },
    },
  },
  created() {
    console.log("this.mpdal", this.modal);
  },
  methods: {
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.$emit("change", modal);
      this.$emit(eventName, ...options);
    },

    // 传入编辑数据
    setEditCarInfo(params, type) { // type:是否有选择默认车组
      this.defaultGroup = type;
      if (!type) this.carGroupInfo = params;

      let label, id = '';

      label = type ? params.groupName : params.parentName;
      id = type ? params.groupId : params.parentId;

      this.selecteds = [{ label: label, id: id }];
    },

    validateData() {
      const { isAdminUser } = this;
      let { groupName, parentId, phone, rephone } = this.carGroupInfo;

      // 上级车组Id是否有效
      const isValidParentId = parentId > -1;

      // 非总帐号, 上级车组必选
      if (!isAdminUser) {
        if (!isValidParentId) {
          this.$message.warning('请选择有效上级车组');
          return;
        }
      } else {
        // 总账号, 上级车组没选, 则默认在根目录下添加车组
        if (!isValidParentId) {
          this.carGroupInfo.parentId = -1;
        }
      }

      if (groupName.length < 2) {
        this.$message({
          message: "车组名称至少2位",
          type: 'warning'
        });
        return false;
      }
      if (phone) {
        let regs = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
        if (!regs.test(phone)) {
          this.$message.error("手机号码格式不正确 , 请重新输入！");
          this.carGroupInfo.phone = "";
          return false;
        } else {
          //  this.concact = this.carGroupInfo.phone;
        }
      }
      if (rephone) {
        let regs = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
        if (!regs.test(rephone)) {
          this.$message.error("联系电话格式不正确 , 请重新输入！");
          this.carGroupInfo.rephone = "";
          return false;
        } else {
          //  this.concactNumber = this.carGroupInfo.rephone;
        }
      };
      return true;
    },
    onSaveData() {
      if (!this.validateData()) return;
      let uuid = "";
      const addFiles = this.groupPhotos.filter((p) => !p.uuid && p.file);
      const upFiles = this.groupPhotos.filter((p) => {
        if (p.uuid) uuid = p.uuid;
        return p.uuid && p.file;
      });

      let action = this.groupId === -1 ? addVehGroup : updateVehicleGroup;
      let params = { ...this.carGroupInfo, organization: this.carGroupInfo.groupAttribute };
      if (this.showCompanyName) {
        params.platformtype = 1;
      } else {
        delete params.platformtype;
        delete params.companytype;
      }

      action({ jsonData: params }).then((res) => {
        if (!res.flag) return res.msg && this.$message({
          message: res.msg,
          type: 'warning'
        });
        const upParams = {
          uuid: upFiles.filter((p) => p.file).map((p) => p.uuid)[0],
          file: upFiles.map((p) => p.file),
          sorts: upFiles.map((p) => p.sort),
        };

        const addParams = {
          uuid,
          groupId: this.groupId === -1 ? res.obj.groupId : this.groupId,
          file: addFiles.map((p) => p.file),
          sorts: addFiles.map((p) => p.sort),
        };
        if (upFiles.length) groupUpdatephotos(upParams);
        if (addFiles.length) groupUploadPhotos(addParams);

        this.onModalChange(false, "on-ok", { ...res.obj });
      });
    },
    groupDelete(index) {
      this.groupPhotos[index].path="";
      this.groupPhotos[index].path="";
      console.log('this.groupphotos :>> ', this.groupPhotos);
    },
    // 选择车组
    Confirm(node) {
      this.carGroupInfo.parentId = node.groupId;
      this.carGroupInfo.parentName = node.groupName;
    },

    // 清除选择
    clearClick() {
      this.carGroupInfo.parentId = -1;
      this.carGroupInfo.parentName = '';
    },

    //车组车辆搜索框选择
    onSelect(node) {
      this.carGroupInfo.parentId = node.groupId;
      this.carGroupInfo.parentName = node.groupName;
    },

    onSelectPhoto(files, extra) {
      extra.file = files;
    },
    getGroupPhotos() {
      groupGetphotos({ groupId: this.groupId }).then(res => {
        if (!res.flag) return;
        if (res.obj) {
          console.log('this.groupphotos :>> ', this.groupPhotos);
          res.obj.forEach((p) => {
            let item = this.groupPhotos.find((q) => q.sort === p.sort);
            item.uuid = p.uuid;
            item.path = p.ftpServerPath + p.path;
          });
        }
      });
    },
    initCarGroup() {
      return {
        groupId: -1,
        groupName: "",
        parentId: -1,
        parentName: "",
        phone: "",
        companytype: "渣土公司", //企业类型
        remark: "",
        area: "",
        scope: "",
        transport: "",
        authority: "",
        representative: "",
        rephone: "",
        readdress: "",
        groupType: '其他',
        contacts: "",
        platformtype: "",
        groupAttribute: "",
        organization: "",
        machineryProductType: "", //产品类型
        machineryEquipmentType: "", //设备类型
      };
    },
    initGroupPhotos() {
      let arr = [];
      for (let i = 0; i < 4; i++) {
        let obj = {
          sort: i + 1,
          uuid: '',
          path: '',
          file: '',
          type: ['法人身份证', '营业执照', '资质证明', '其他'][i]
        };
        arr.push(obj);
      }
      return arr;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-cargroup-info-wrapper {
  position: relative;

  .info_name {
    background: #eee;
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #333;
  }
  .visible_name {
    font-size: 24px;
    // font-family: 'Courier New', Courier, monospace;
    display: inline-block;
    margin-right: 20px;
  }
  .tip {
    font-size: 12px;
    color: #e6a23c;
  }
}
.cargroup-info-content {
  width: 100%;
  height: 560px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 47%;
    height: 46px;
    line-height: 46px;
    display: flex;
    margin: 0 10px;
    & > span {
      display: inline-block;
      width: 30%;
    }
    .last-item {
      width: 69%;
      box-sizing: border-box;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .item-cell {
    width: 100%;
    margin: 0 10px;
    height: 170px;
    &__photos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > div {
        height: 140px;
        margin: 2px;
        width: calc((100% - 16px) / 4);
      }
    }
  }
  .required::before {
    content: '*';
    color: red;
    font-weight: bold;
    margin-right: 1px;
    font-size: 17px;
  }

  .car-group-dropdown {
    & > div:first-child {
      width: 100%;
    }
  }
  .drop-down-menu {
    height: 170px;
    overflow: auto;
  }
  .grouptype-item {
    .ivu-select-dropdown {
      max-height: 150px !important;
    }
  }
}
.dropmenu {
  height: 280px;
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 10px;
}

@media only screen and (max-width: 1366px) {
  .cargroup-info-content {
    height: 406px;
  }
}
</style>

