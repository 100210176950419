
<template>
  <el-popover width="400" trigger="click" v-model="visible" placement="bottom">
    <GroupTree
      style="height:300px"
      :showCheckbox="false"
      :ellipsis="true"
      ref="groupTree"
      @checkChange="checkChange"
      @nodeClick="selectVGroup"
      :useDefault="true"
      :isSearch="true"
    />
    <el-button  slot="reference">+</el-button>
  </el-popover>
</template>
<script>
import GroupTree from '@/components/GroupTree';

export default {
  name: 'SelectGroupPopover',
  model: {
    prop: 'test',
    event: 'modal-change'
  },
  components: {
    GroupTree,
  },

  created() {

  },
  data() {
    return {
      visible: false,
    }
  },
  props: {
    test: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    selectVGroup: function (manageWay) {
      this.$emit('nodeClick', manageWay);
      this.visible = false
    },
    checkChange(node, checked) {
      this.$emit('nodeClick', node);
      this.visible = false
      // console.log(node, checked);
    },
    nodeClickTest(data) {
      console.log(data)
    },
  },
}
</script>
