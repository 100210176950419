<template>
  <div class="searchvehiclegroup-wrapper">
    <el-autocomplete v-model="searchStr"
      class="search-outer"
      clearable
      ref="searchRef"
      :size="size"
      :trigger-on-focus="true"
      :hide-loading="true"
      :disabled="disabled || isTmpUser"
      :fetch-suggestions="fetchSearch"
      :placeholder="placeholder"
      :popper-class="['popper-outer',searchPopperClass].join(' ')"
      @select="onSelect"
      @clear="onClear">
      <template v-slot="{item}">
        <div class="item_title"
          :title="item.type !== -1 ? '车牌：' + item.plate : ''">
          <span v-if="item.type === -1">[车组]{{ item.groupName }}</span>
          <span v-if="item.type === 1">[车牌]{{ item.plate }}[终端]{{item.terminalNo}}</span>
          <span v-if="item.type === 3">[SIM]{{ item.sim }}[车牌]{{ item.plate }}</span>
          <span v-if="item.type === 2">[终端]{{ item.terminalNo }}[车牌]{{ item.plate }}</span>
          <span v-if="item.type === 4">[车主]{{ item.owner }}[车牌]{{ item.plate }}</span>
        </div>
      </template>
      <div v-if="!disabled && dropdown && !(menuProps.nogroup && menuProps.novehicle)"
        slot="append"
        @click.stop="onExpandDropMenu(!dropmenuObj.visible)">
        <i class="fa fa-sitemap drop-icon"></i>
      </div>
    </el-autocomplete>
    <div ref="dropMenu"
      v-show="dropmenuObj.visible"
      :class="[menuProps.className, 'dropmenu-outer']"
      @click.stop="onExpandDropMenu(true)">
      <div class="menu-help">
        <label v-if="!multiple && menuProps.novehicle">
          单击
          <code>车组</code>选择
          <code>车组</code>
        </label>
        <label v-if="!multiple && !menuProps.nogroup && !menuProps.novehicle">
          双击
          <code>车组</code>选择
          <code>车组</code>,单击
          <code>车辆</code>选择
          <code>车辆</code>
        </label>
        <label v-if="!multiple && menuProps.nogroup && !menuProps.novehicle">
          单击
          <code>车辆</code>选择
          <code>车辆</code>
        </label>
        <label v-if="multiple && !menuProps.nogroup">
          双击
          <code>车组</code>选择
          <code>车组及子车组</code>,勾选
          <code>车组</code>选择
          <code>车组</code>
        </label>
        <label v-if="multiple && menuProps.nogroup">
          勾选
          <code>车辆</code>选择
          <code>车辆</code>
        </label>
      </div>
      <div class="menu-content">
        <div class="menu-left">
          <ul :id="groupTreeId"
            class="ztree cargrouptree group-outer"></ul>
        </div>
        <div v-if="!menuProps.novehicle"
          class="menu-right"
          v-loading="isVehicleLoading">
          <label class="no-data"
            v-show="!vehicleList.length">无数据</label>
          <el-checkbox v-if="enableChkAllVehicle"
            v-model="checkAllObj.checked"
            :indeterminate="checkAllObj.isIndeterminate"
            @change="onChangeChkAll">全选</el-checkbox>
          <div class="row-veh"
            v-for="item in vehicleList"
            :key="item.vehicleId"
            :title="item.plate"
            @click.stop="onClickVehicle(item)">
            <el-checkbox class="row-chkbox"
              v-if="multiple"
              v-model="item.checked"
              @change="onChangeChk(item)"></el-checkbox>
            <img src="/images/car1.png" />
            <label class="row-plate">{{item.plate}}</label>
          </div>
        </div>
      </div>
      <div v-if="multiple"
        class="menu-bottom">
        <label v-if="!menuProps.nogroup">
          已选择
          <code>{{checkAllObj.gorupCheckedCount}}</code>个
          <code>车组</code>
        </label>
        <label v-if="!menuProps.novehicle">
          &nbsp;&nbsp;&nbsp;&nbsp;
          已选择
          <code>{{getVehicleListChecked().length}}</code>个
          <code>车辆</code>
        </label>
        <el-button type="primary"
          size="mini"
          @click.stop="onConfirmClick">确 认</el-button>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * 搜索车组车辆组件
 * searchProps：搜索框属性设置
 * menuProps：下来列表属性设置
 * dropdown：是否有车组车辆下拉列表
 * multiple：下拉列表是否可多选，dropdown为true时有效
 */

/**
 * searchProps.nogroup：不搜索车组
 * searchProps.novehicle：不搜索车辆
 */

/**
 * nogroup，novehicle不能都为true
 * menuProps.nogroup：不能选择车组
 * menuProps.novehicle：不显示车辆列表
 */

/**
 * menuProps.groupIds：已勾选车组Id，multiple为true有效
 * menuProps.vehicleIds：已勾选车辆Id，multiple为true有效
 */

/**
 * 单选时操作
 * 双击车组节点，选择车组
 * 单击车辆节点，选择车辆
 */

/**
 * 多选时操作
 * 双击车组节点，选择当前车组及子车组节点
 * 勾选车组节点，只选择当前车组
 * 勾选车辆节点，选择车辆
 */


/**
 * 事件：clear
 * 说明：清空搜索框
 * 回调参数：无
 */

/**
 * 事件：select
 * 说明：选择搜索的车组车辆
 * 回调参数：(data)
 * data：选择的车组车辆对象
 */

/**
 * 事件：on-confirm
 * 说明：确认选择车组车辆
 * 回调参数: ({group, vehicle, groupList, vehicleList})
 * group：选择的车组对象，multiple为false有效
 * vehicle：选择的车辆对象，multiple为false有效
 * groupList：选择的车组对象列表，multiple为true有效
 * vehicleList：选择的车辆对象列表，multiple为true有效
 */


import { debounce, throttle } from 'lodash';

import { mapState, mapGetters, mapActions } from 'vuex';

import { randomStr, delay } from '@/js/common';

import { getVehiclesByGroupId } from '@/api/getManagementData.js';
import { loadGroups } from '@/api/getData';

export default {
  name: 'SearchVehicleGroup',
  model: {
    prop: 'iQstr',
    event: 'modal-change'
  },
  props: {
    userId: { // 指定用户，搜索该用户下车组车辆信息
      type: Number,
      default: 0,
      required: false,
    },
    iQstr: { //搜索框显示内容
      type: String,
      default: ''
    },
    searchProps: { //搜索框属性设置
      type: Object,
      default() {
        return {
          nogroup: false, //是否搜索车组
          novehicle: false, //是否搜索车辆，包括车牌，终端号，SIM等
        };
      }
    },
    dropdown: { //是否显示车组车辆下拉列表
      type: Boolean,
      default: false
    },
    multiple: { //多选模式, dropdown为true有效
      type: Boolean,
      default: false
    },
    groupSpan: { // 多选模式下，是否允许跨车组勾选车辆
      type: Boolean,
      default: false,
    },
    maxGroup: { //多选模式下，选择车组最大数
      type: Number,
      default: -1,
    },
    maxVehicle: { //多选模式下，选择车辆最大数
      type: Number,
      default: -1,
    },
    menuProps: { //dropdown为true时有效
      type: Object,
      default() {
        return {
          className: '',
          nogroup: false, //下来列表车组是否可选择
          novehicle: false, //下来列表是否显示车辆
          groupIds: [], //已选择车组Id列表, 多选模式有效
          vehicleIds: [], //已选择车辆Id列表，多选模式有效
        };
      },
      validator(obj) {
        return !(obj.nogroup && obj.novehicle);
      }
    },
    placeholder: {
      type: String,
      default() {
        const { nogroup, novehicle } = this.searchProps;
        if (nogroup) return '设备名称/SIM';//终端号
        if (novehicle) return '车组';
        return "车组/设备名称/SIM";//终端号
      }
    },
    size: {
      type: String,
      default: 'small'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      qstr: '',
      groupTreeId: randomStr(),
      dropmenuObj: {
        visible: false
      },
      vehicleList: [], //当前车辆列表
      allVehicleMap: new Map(), //所有车辆列表
      checkAllObj: {
        checked: false,
        isIndeterminate: false,
        gorupCheckedCount: 0, //勾选车组数量
      },
      searchPopperClass: `popper-${ randomStr() }`,
      treeList: [],
      isVehicleLoading: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isTmpUser', 'realGroups']),
    searchStr: {
      get: function () {
        return this.iQstr || this.qstr || '';
      },
      set: function (value) {
        this.onModalChange(value);
      }
    },
    types() {
      let arr = [];
      if (!this.searchProps.nogroup) arr.push(-1);
      if (!this.searchProps.novehicle) arr.push(1, 2, 3, 4);
      return arr;
    },
    enableChkAllVehicle() {
      return this.multiple
        && this.vehicleList.length;
    },
  },
  watch: {
    iQstr(val) {
      this.$nextTick(_ => {
        if (val === "") {
          this.qstr = "";
        }
      });
    },
    userId: {
      immediate: true,
      handler(val) {
        this.treeList = [];
        if (!val) return;
        if (this.user && this.user.userId === this.userId) {
          this.treeList = this.realGroups;
        } else if (this.userId) {
          loadGroups(this.userId)
            .then(res => this.treeList = res.filter(p => p.groupId && p.parentId));
        }
      }
    }
  },
  beforeMount() {
    document.addEventListener('click', () => this.onExpandDropMenu(false));
  },
  mounted() {

    // if (this.$refs.searchRef && this.iQstr) this.$refs.searchRef.focus();
    // document.body.appendChild(this.$refs.dropMenu);
  },
  methods: {
    ...mapActions(['searchVehicleGroup']),
    onModalChange(value) {
      this.qstr = value;
      this.$emit('modal-change', value);
      if (!(value && value.trim())) this.onClear();
    },
    getGroupListChecked() {
      const treeObj = this.getZTreeObj();
      const list = !treeObj ? [] : treeObj.getNodesByFilter(p => p.checked)
        .map(p => ({
          groupId: p.groupId,
          groupName: p.groupName,
          parentId: p.parentId,
          groupCount: p.groupCount,
          groupType: p.groupType
        }));
      this.checkAllObj.gorupCheckedCount = list.length;
      return list;
    },
    getVehicleListChecked() {
      return [...this.allVehicleMap.values()]
        .reduce((sum, arr) => sum.concat(arr), [])
        .filter(p => p.checked)
        .map(p => ({
          vehicleId: p.vehicleId,
          plate: p.plate,
          groupId: p.groupId,
          groupName: p.groupName,
          terminalNo: p.terminalNo,
          sim: p.sim,
          terminalType: p.terminalType,
          vehicleShape: p.vehicleShape,
          plateColor: p.plateColor,
        }));
    },
    onClickVehicle(item) {
      if (this.multiple) return;
      this.onModalChange(item.plate);
      this.$emit('on-confirm', {
        vehicle: {
          vehicleId: item.vehicleId,
          plate: item.plate,
          groupId: item.groupId,
          groupName: item.groupName,
          terminalNo: item.terminalNo,
          sim: item.sim,
          terminalType: item.terminalType,
          vehicleShape: item.vehicleShape,
          plateColor: item.plateColor,
        }
      });
      this.onExpandDropMenu(false);
    },
    onConfirmClick() {
      //勾选车组
      const groupList = this.getGroupListChecked();
      //勾选车辆
      const vehicleList = this.getVehicleListChecked();
      this.$emit('on-confirm', { groupList, vehicleList });
      this.onExpandDropMenu(false);
    },
    onChangeChkAll(checked) {
      this.checkAllObj.isIndeterminate = false;
      this.vehicleList.forEach(p => p.checked = checked);
    },
    onChangeChk(item) {
      if (!this.multiple) return;
      const chklen = this.vehicleList.filter(p => p.checked).length;
      this.checkAllObj.isIndeterminate = chklen > 0 && chklen < this.vehicleList.length;
      this.checkAllObj.checked = chklen === this.vehicleList.length;
    },
    onExpandDropMenu(visible) {
      if (this.dropmenuObj.visible === visible) return;
      this.dropmenuObj.visible = visible;
      this.vehicleList = [];
      this.allVehicleMap.clear();
      this.initTree(this.userId ? this.treeList : this.realGroups);
      this.$nextTick(() => {
        this.getGroupListChecked();
        const searchPopperDom = document.querySelector(`.${ this.searchPopperClass }`);
        if (searchPopperDom) searchPopperDom.style.display = 'none';
      });
    },
    onNodeCreated(e, treeid, treeNode) {
      let { groupId, parentId, groupCount } = treeNode;
      const isTmp = !(groupId && parentId);
      const sObj = $(`#${ treeNode.tId }_a`);
      if (isTmp) {
        sObj.css('color', 'red');
      } else if (groupCount > 0) {
        let span = `<span id='${ treeNode.tId }_total'>(共:${ groupCount }车组)</span>`;
        sObj.append(span);
      }
    },
    onNodeBeforeCheck(treeid, treeNode) {
      //点击勾选框，只勾选当前车组，不勾选子车组
      treeNode.checked = !treeNode.checked;
      this.getZTreeObj().updateNode(treeNode, false);
      this.getGroupListChecked();
      return false;
    },
    onNodeChecked(e, treeid, treeNode) {
      e.stopPropagation();
    },
    onNodeBeforeClick(treeid, treeNode) {
    },
    onNodeBeforeDblClick(treeid, treeNode) {
    },
    onNodeClick(e, treeid, treeNode) {
      e.stopPropagation();
      if (e.data.isDblClick) return; //双击事件时，直接还回

      const { novehicle, vehicleIds } = this.menuProps;

      //多选模式下，并且没有车辆列表，单击车组节点无效果，直接返回
      if (this.multiple && novehicle) return;
      //单选模式下，并且没有车辆列表，单击车组节点，选择该车组
      if (!this.multiple && novehicle) return this.onNodeDblClick(e, treeid, treeNode);

      if (this.allVehicleMap.has(treeNode.groupId)) {
        this.vehicleList = this.allVehicleMap.get(treeNode.groupId);
        return this.onChangeChk();
      }

      this.vehicleList = [];
      this.isVehicleLoading = true;
      getVehiclesByGroupId({ groupId: treeNode.groupId })
        .then(res => {
          this.isVehicleLoading = false;
          if (!(res.flag && res.obj)) {
            return this.$message.warning(res.msg || '无数据');
          }
          if (!res.obj.length) return;

          if (this.multiple && vehicleIds && vehicleIds.length) {
            res.obj.forEach(p => p.checked = vehicleIds.includes(p.vehicleId));
          }

          if (this.multiple && !this.groupSpan) {
            //跨车组查询
            this.allVehicleMap.clear();
          }

          this.allVehicleMap.set(treeNode.groupId, res.obj);
          this.vehicleList = this.allVehicleMap.get(treeNode.groupId);
          this.onChangeChk();
        });
    },
    onNodeDblClick(e, treeid, treeNode) {
      if (!treeNode) return;
      e.stopPropagation();
      e.data.isDblClick = true; //双击事件，不执行单击事件
      delay(() => e.data.isDblClick = false, 300);
      const { nogroup } = this.menuProps;

      if (nogroup) return;

      if (this.multiple) { //双击勾选车组及子车组
        treeNode.checked = !treeNode.checked;
        this.getZTreeObj().updateNode(treeNode, true);
        return this.getGroupListChecked();
      }

      this.onModalChange(treeNode.groupName);
      this.$emit('on-confirm', {
        group: {
          groupId: treeNode.groupId,
          groupName: treeNode.groupName,
          parentId: treeNode.parentId,
          groupCount: treeNode.groupCount,
          groupType: treeNode.groupType
        }
      });
      this.onExpandDropMenu(false);
    },
    getZTreeObj() {
      return $.fn.zTree.getZTreeObj(this.groupTreeId);
    },
    initTree(treeList) {
      if (this.getZTreeObj()) return;
      if (!treeList.length) return;

      const { nogroup, groupIds, vehicleIds } = this.menuProps;

      let data = treeList;
      if (this.multiple && !nogroup && groupIds && groupIds.length) {
        data = treeList.map(p => Object.assign({ ...p, checked: groupIds.includes(p.groupId) }));
      }

      let settings = {
        check: {
          enable: this.multiple && !nogroup,
          chkboxType: { Y: "s", N: "s" }
        },
        data: {
          key: {
            name: 'groupName',
            title: 'groupName'
          },
          simpleData: {
            enable: true,
            idKey: 'groupId',
            pIdKey: 'parentId',
            rootPId: -1
          }
        },
        view: {
          selectedMulti: false,
          dblClickExpand: false
        },
        callback: {
          onNodeCreated: this.onNodeCreated.bind(this),
          onClick: debounce(this.onNodeClick.bind(this), 250),
          onDblClick: this.onNodeDblClick.bind(this),
          onCheck: this.onNodeChecked.bind(this),
          beforeCheck: this.onNodeBeforeCheck.bind(this),
          // beforeClick: debounce(this.onNodeBeforeClick.bind(this), 200),
          // beforeDblClick: this.onNodeBeforeDblClick.bind(this),
        }
      };
      $(`#${ this.groupTreeId }`).empty();
      $.fn.zTree.init($(`#${ this.groupTreeId }`), settings, data);
    },
    selectText(data) {
      let title = "";
      let text = "";
      if (data.type === -1) {
        title = '车组';
        text = data.gn || data.groupName;
      } else if (data.type === 1) {
        title = "设备名称";
        text = data.plate;
      } else if (data.type === 3) {
        title = "SIM";
        text = data.sim;
      } else if (data.type === 2) {
        title = "终端号";
        text = data.terminalNo;
      } else if (data.type === 4) {
        title = "车主";
        text = data.owner;
      }
      return {
        title: title,
        text: text
      };
    },
    onSelect(data) {
      this.onModalChange(this.selectText(data).text);
      this.$emit('select', data);
    },
    onClear() {
      this.$emit('clear');
    },
    fetchSearch(qStr, cb) {
      this.onExpandDropMenu(false);
      qStr = qStr.trim().toLowerCase();
      if (qStr) {
        this.searchVehicleGroup({
          keyword: qStr,
          source: this.userId ? this.treeList : null,
        }).then(({ flag, obj }) => {
          if (flag && obj) {
            cb(obj.filter(p => this.types.includes(p.type)));
          }
        });
      } else if (cb) {
        cb([]);
      }
    },
  }
}
</script>

<style lang="scss">
// @import '../style/metroStyle/metroStyle.css';
.searchvehiclegroup-wrapper {
  position: relative;
  width: 220px;
  .search-outer {
    position: relative;
    width: 100%;
    cursor: pointer;
    .drop-icon {
      font-size: 16px;
      margin: 0 -8px;
      width: 32px;
      text-align: center;
    }
    .query-icon {
      color: rgb(42, 119, 185);
      font-size: 16px;
      line-height: 28px;
      color: #2a77b9;
    }

    .el-input-group__prepend {
      padding: 0;
    }
    .el-input-group__append {
      padding: 0 8px;
      line-height: 28px;
    }
  }

  .item_title {
    width: 100%;
    & > span {
      word-wrap: break-word;
    }
  }
}
.popper-outer {
  width: unset !important;
}

.dropmenu-outer {
  background: white;
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  min-width: 335px;
  width: calc(100% - 5px);
  height: 400px;
  box-shadow: 0 0 3px grey;
  border-radius: 3px;
  margin: 2px;
  padding: 3px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  user-select: none;
  .menu-content {
    margin-bottom: 5px;
    display: flex;
    flex-grow: 1;
    height: calc(100% - 18px - 40px);
  }
  .menu-left {
    height: 100%;
    overflow: auto;
    flex-grow: 1;
  }
  .menu-right {
    height: 100%;
    width: 50%;
    min-width: 50%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-left: 15px;
    border-left: 1px solid #b0b5b9;
  }
  .no-data {
    text-align: center;
  }
  .row-veh {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 20px;
  }
  .row-chkbox {
    margin-right: 10px;
  }
  .row-plate {
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }
  .menu-bottom {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #b0b5b9;
    font-size: 12px;
    button {
      margin-left: 15px;
    }
  }
  .group-outer {
    &.ztree li a.curSelectedNode {
      background-color: #c5c5c5;
      opacity: 1;
    }
  }
  .menu-help {
    text-align: center;
    font-size: 12px;
  }
  code {
    color: red;
  }
}
</style>

