<template>
  <div class="filter-vehicle">
    <el-input
      type="text"
      v-model="searchVehicle"
      :disabled="disabled"
      @change="searchVehicleData"
    ></el-input>
    <div class="r-mask" @click="onCancel" :class="{ 'r-modal-show': visible}"></div>
    <div class="r-modal">
      <div class="l-modal-content" :class="{ 'r-modal-show': visible}">
        <div
          class="l-user-drop-down-menu"
          v-for="item in searchVehicleDataList"
          :key="item.vehicleId"
          @click="selectedUser(item)"
        >
          <el-row>
            <i-col :span="20">{{item.groupName}}</i-col>
            <i-col :span="4">车组</i-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { loadGroups } from '@/api/getData';

export default {
  props: {
    userId: {
      type: Number,
      default: 0,
      required: false,
    },
    groupName: {
      type: String
    },
    groupId: {
      type: Number,
      default: -2
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      visible: true,
      selectedData: '',
      searchVehicle: '',
      searchVehicleDataList: [],
      treeList: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    ...mapGetters({
      zNodes: 'realGroups'
    })
  },
  watch: {
    groupId: {
      immediate: true,
      handler: function (val) {
        this.searchVehicle = ''
        if (val > -1) {
          this.selectedData = this.zNodes.find(p => p.groupId === val)
          this.searchVehicle = this.selectedData ? this.selectedData.groupName : val > 1 ? '车组管理' : ''
        } else if (this.groupName) {
          this.searchVehicle = this.groupName
        }
      }
    },
    searchVehicle: {
      immediate: true,
      handler: function (val) {
        let groupId = this.groupId //-2
        let str = (val || '').trim().toLowerCase()
        this.selectedData = this.zNodes.find(p => p.groupName && p.groupName.toLowerCase() === str)
        if (this.groupId === -1 && str === '车组管理') {
          groupId = -1
        }
        this.$emit('filterVehicle', this.selectedData || { groupId, groupName: str, groupType: '', groupCount: 0, parentId: -1 })
      }
    }
  },
  created() {
    if (!this.zNodes.length) {
      this.loadGroups()
    }
  },
  beforeMount() {
    console.log(22222)
  },
  methods: {
    ...mapActions([
      'loadGroups'
    ]),
    searchVehicleData() {
      if (this.searchVehicle !== '') {
        this.visible = false
        let filterList = this.zNodes.filter(item => item.groupName.toLowerCase().indexOf(this.searchVehicle.toLowerCase()) > -1);
        if (filterList.length > 8) {
          this.searchVehicleDataList = filterList.filter((item, index) => index < 8);
        } else {
          this.searchVehicleDataList = filterList
        }
        this.visible = false;
      } else {
        this.visible = true;
        this.searchVehicleDataList = [];
      }
    },
    // 搜索弹窗取消
    onCancel: function () {
      this.visible = true
    },
    // 搜索弹窗选中
    selectedUser(val) {
      this.visible = true;
      this.selectedData = val;
      this.searchVehicle = val.groupName;
      // this.$emit('filterVehicle', val)
    },
  }
}
</script>


<style lang="scss" scoped>
.filter-vehicle {
  .r-modal-show {
    display: none;
  }
  .r-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
  }
  .r-modal {
    position: relative;
    .l-modal-content {
      background-color: white;
      position: absolute;
      top: 5px;
      left: 0px;
      z-index: 2001;
      box-shadow: 0 0 5px gray;
      border-radius: 5px;
      min-width: 100%;
      padding: 5px;
      .l-user-drop-down-menu {
        padding: 5px;
      }
      .l-user-drop-down-menu:hover {
        background-color: #f8f8f9;
        color: #5cadff;
        cursor: pointer;
      }
    }
  }
}
</style>

