<template>
  <div>
    <!-- 绑定车组弹框 -->
    <el-row>
      <el-col :span="12">
        <div class="choice-car-left">
          <!-- <div class="choice-car-left-search">
            <el-row>
              <el-col :span="24">
                <SelectGroupTree
                  placeholder="车组"
                  class="search-input"
                  @on-select="confirmList"
                />
              </el-col>
            </el-row>
          </div> -->
          <div class="choice-car-left-item" style="height: 485px;">
            <div class="misstree"></div>
            <GroupTree
              isSearch
              style="height: 95%;"
              :showTitle="true"
              :showCheckbox="true"
              :ellipsis="true"
              ref="groupTree"
              @checkChange="checkChange"
              :useDefault="true"
              :selecteds="checkedList"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="icon-wrap">
          <Iconfont :size="32" name="icon-xiayibu" class="iconClass"></Iconfont>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="choice-car-right">
          <div class="choice-car-right-wrap">
            <span class="right-title">绑定列表</span>
            <span class="right-clear" @click="clearChecked">
              <Iconfont :size="12" name="icon-qingkong" class="iconClass"></Iconfont>清空
            </span>
          </div>
          <div class="choice-car-right-item">
            <div class="misstree"></div>
            <GroupTree
              :showCheckbox="false"
              :ellipsis="true"
              ref="SelectGroupTree"
              :propGroupList="selectGroupList"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="footer">
      <div v-if="noExistGroupIds.length" class="tip-label">
        <label>提示：用户和车组绑定关系错误的，请联系上级用户更改。</label>
      </div>
      <el-button
        :disabled="!!noExistGroupIds.length"
        @click="bindVehicleOk"
        type="primary"
      >确 定</el-button>
      <el-button
        type="primary"
        @click="bindVehicleCancel"
        style="margin-left: 8px"
      >取 消</el-button>
    </div>
  </div>
</template>

<script>
import { getUserBindGroup, boundGroups } from "@/api/getManagementData.js";
import { mapActions } from 'vuex';
import GroupTree from '@/components/GroupTree';
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
import { treeToArray, arrayToTree } from '@/utils/treeHelper.js';
export default {
  props: {
    parentUser: {
      type: Object,
      required: false,
      default() {
        return { userId: 0 };
      }
    },
    type: {
      type: String,
      default: '0'
    },
    userId: {
      type: String
    },
    checkOnly: {
      type: Boolean
    }
  },
  components: {
    GroupTree, 
    SelectGroupTree
  },
  data() {
    return {
      selectedNodes: [],
      groupId: -2,
      noExistGroupIds: [],
      selectGroupList: [],
      checkedList: []
    };
  },
  computed: {
  },
  watch: {
    userId: {
      immediate: true,
      handler: function (val) {
        if (val && val !== '0') {
          this.getUserBindVehicles(val);
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    clearChecked() {
      this.$refs.groupTree.cancelAll();
    },
    confirmList(data) {
      console.log(data);
    },
    bindVehicleOk() {
      if (this.checkOnly) {
        this.$emit('on-cancel');
        this.$emit('bindVehicleBtn', {
          success: true,
          data: this.selectedNodes
        });
        this.selectedNodes = [];
      } else {
        if (!this.selectedNodes.length) return this.$message.warning('绑定车组不能为空');
        let selectedGroupIds = [];
        this.selectedNodes.forEach(function (item) {
          selectedGroupIds.push(item.groupId);
        });
        let paramsTemp = {
          groupIds: selectedGroupIds.join(','),
          userId: this.userId
        };
        let data = `boundGroup=${JSON.stringify(paramsTemp)}`;
        boundGroups(data).then(res => {
          if (res.flag == 1) {
            this.selectedNodes = [];
            this.$message.success(res.msg);
            this.$emit('on-cancel');
            this.$emit('bindVehicleBtn', {
              success: true
            });
          } else {
            this.bindVehicleCancel();
            this.$message.error(res.msg);
          }
        });
      }
    },
    bindVehicleCancel() {
      this.selectedNodes = [];
      this.$emit('on-cancel');
      this.$emit('bindVehicleBtn', {
        success: false
      });
    },
    checkChange(node, checked, checkList) {
      this.selectGroupList = checkList.map(({ children, ...item }) => item);
      this.selectedNodes = [...this.selectGroupList];
    },
    // 获取当前用户绑定车组
    getUserBindVehicles(userId) {
      this.selectedNodes = [];
      getUserBindGroup({ userId: userId })
        .then(res => {
          if (res.flag == 1) {
            this.checkedList = res.obj.map(item => {
              return { id: item.groupId };
            });
            this.selectGroupList = [...res.obj];
            this.selectedNodes = [...res.obj];

          } else {
            this.$message.error(res.msg);
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.choice-car-left,
.choice-car-right {
  padding: 2px;
}
.choice-car-left-item,
.choice-car-right-item {
  height: 450px;
  border: 1px solid #ccc;
  overflow: auto;
}

.misstree {
  width: 100%;
}
.choice-car-left-search {
  font-size: 12px;
  height: 35px;
  line-height: 35px;
}
.choice-car-left-search > input {
  height: 25px;
  line-height: 25px;
}
.footer {
  margin-top: 12px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .tip-label {
    color: red;
    font-size: 13px;
    margin-right: 10px;
  }
}
.icon-wrap {
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choice-car-right-wrap {
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  // background: #7c7c7d;
  background-image: linear-gradient(to right, #e7e8e9, #7c7c7d);
  display: flex;
  color: #fff;
  padding: 0 10px;
  justify-content: space-between;
  .right-title {
    font-size: 15px;
    color: #555;
  }
  .right-clear {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.search-input {
  width: 100%;
  margin-bottom: 10px;
}
</style>

