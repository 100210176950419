import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';


const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);


const get = (action, options) => axios.get(`exportReport/${action}.do`, options);


export const axiosGet = axios.get;


export const exportExcel = (params, options = {}) =>
    get('exportExcel', { responseType: 'blob', ...options, params }).then(res => URL.createObjectURL(res));


/**
 * 轨迹回放数据导出
 * @param params.plate 
 * @param params.vehicleId 
 * @param params.startTime 
 * @param params.endTime 
 * @param params.filterTime 
 */
export const trackback2Excel = (params, options) => exportExcel({...params, type: 18 }, options);

/**
 * 用户管理数据导出
 */
export const userManage2Excel = () => exportExcel({ userId: null, type: 19 });

/**
 * 空重载报表导出
 * @param {*} params.vehicleId 车辆ID 
 * @param {*} params.groupId 车组ID
 * @param {*} params.flag 0: 车组，1：车辆
 * @param {*} params.loadStatus 空重载状态 0：空载 1：半载 4：满载
 * @param {*} params.startTime 开始时间 
 * @param {*} params.endTime 结束时间
 */
export const heavyload2Excel = params => exportExcel({...params, type: 22 });


/**
 * 空重载明细报表导出
 * @param {*} params.vehicleId 车辆ID 
 * @param {*} params.loadStatus 空重载状态 0：空载 1：半载 4：满载
 * @param {*} params.startTime 开始时间 
 * @param {*} params.endTime 结束时间
 */
export const heavyloadDetail2Excel = params => exportExcel({...params, type: 23 });




/**
 * 导出报表
 */
export const exportExcels = (val) => { return axios.get('exportReport/exportExcel.do', { responseType: 'blob', params: val }); };


/**
 * 通用导出报表
 */
export const exportData = (url, val) => { return axios.get(url, { responseType: 'blob', params: val }); };

/**
 * 统计服务通用导出报表
 */
export const exportParams = (url, val) => { return axios2.get(url, { responseType: 'blob', params: val }); };


/**
 * 查询导出文件准备进度
 * @param {*} fileName 
 */
export const exclestaue = (fileName) => axios.get('systemutil/exclestaue.do', { params: { fileName } });

/**
 * 导出准备好的文件
 * @param {*} fileName 
 */
export const downexcle = (fileName) => axios.get('systemutil/downexcle.do', { responseType: 'blob', params: { fileName } }).then(res => URL.createObjectURL(res));