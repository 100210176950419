import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl);

/**
 * 添加工地信息
 * @param {JSON} val
 */
export const addsite = (val) => { return axios.get('ZtSite/insertztsiteinfo.do', { params: val }); };

/**
 * 获取工地列表
 */
export const findztsiteall = (val) => { return axios.get('ZtSite/findztsiteall.do', { params: val }); };

/**
 * 删除工地信息 
 */
export const deleteztsite = (val) => { return axios.get('ZtSite/deleteztsite.do', { params: val }); };

/**
 * 获取单个工地信息 
 */
export const findztsiteinfo = (val) => { return axios.get('ZtSite/findztsiteinfo.do', { params: val }); };

/**
 * 修改工地信息 
 */
export const updateztsiteinfo = (val) => { return axios.get('ZtSite/updateztsiteinfo.do', { params: val }); };

/**
 * 工地附件上传
 */
export const uploadsitephotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('Id', photoInfo.Id);
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtSite/uploadsitephotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 修改工地附件
 */
export const updatesitephotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtSite/updatesitephotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 获取工地附件
 */
export const getsitephotos = (val) => {
  return axios.get('ZtSite/getsitephotos.do', { params: val });
};

/**
 * 获取当前用户所有渣土车车组
 */
export const getSiteGroups = (val) => { return axios.get('vehicleGroup/getgroupsbyplatformtype.do', { params: val }); };

/* 查看车组附件
 * @param {JSON} val
 */
export const groupGetphotos = (val) => { return axios.get('vehicleGroup/getphotos.do', { params: val }); };

/**
 * 车组资料上传附件
 * @param {JSON} val
 */
export const groupUploadPhotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('groupId', photoInfo.groupId);
  if (photoInfo.uuid) data.append('uuid', photoInfo.uuid);
  return axios.post('vehicleGroup/uploadvehiclegroup.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 车组更新附件
 * @param {JSON} val
 */
export const groupUpdatephotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('uuid', photoInfo.uuid);
  return axios.post('vehicleGroup/updatephotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 上传车辆附件
 * @param {JSON} val
 */
export const uploadVehiclePhotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('vehicleId', photoInfo.vehicleId);
  if (photoInfo.uuid) data.append('uuid', photoInfo.uuid);
  return axios.post('vehicle/uploadvehiclephotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 更新车辆附件
 * @param {JSON} val
 */
export const updateVehiclePhotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('vehicleId', photoInfo.vehicleId);
  if (photoInfo.uuid) data.append('uuid', photoInfo.uuid);
  return axios.post('vehicle/updatephotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 查看车辆附件
 * @param {JSON} val
 */
(val) => { return axios.get('vehicleGroup/getphotos.do', { params: val }); };
export const getVehiclePhotos = (val) => { return axios.get('vehicle/getphotos.do', { params: val }); };

/**
 * 消纳场查询
 */
export const findztearthall = (val) => { return axios.get('ZtEarth/findztearthall.do', { params: val }); };

/**
 * 查询单个消纳场信息
 */
export const findztearthinfo = (val) => { return axios.get('ZtEarth/findztearthinfo.do', { params: val }); };

/**
 * 添加消纳场
 * @param {JSON} val
 */
export const addConsump = (val) => { return axios.get('ZtEarth/insertztearthinfo.do', { params: val }); };

/**
 * 修改消纳场
 * @param {JSON} val
 */
export const updateztearthinfo = (val) => { return axios.get('ZtEarth/updateztearthinfo.do', { params: val }); };


/**
 * 消纳场附件上传
 */
export const uploadearthphotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('Id', photoInfo.Id);
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtEarth/uploadearthphotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 修改消纳场附件
 */
export const updateearthphotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtEarth/updateearthphotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 获取消纳场附件
 */
export const getearthphotos = (val) => {
  return axios.get('ZtEarth/getearthphotos.do', { params: val });
};

/**
 * 删除消纳场
 * @param {JSON} val
 */
export const deleteztearth = (val) => { return axios.get('ZtEarth/deleteztearth.do', { params: val }); };

/**
 * 停车场查询
 */
export const findztparkall = (val) => { return axios.get('ZtPark/findztparkall.do', { params: val }); };

/**
 * 停车场信息录入   
 */
export const addParking = (val) => { return axios.get('ZtPark/insertztparkinfo.do', { params: val }); };

/**
 * 删除停车场信息   
 */
export const deleteParking = (val) => { return axios.get('ZtPark/deleteztpark.do', { params: val }); };

/**
 * 获取单个停车场信息
 */
export const findztparkinfo = (val) => { return axios.get('ZtPark/findztparkinfo.do', { params: val }); };

/**
 * 更新停车场信息
 */
export const updateParking = (val) => { return axios.get('ZtPark/updateztparkinfo.do', { params: val }); };

/**
 * 停车场附件上传
 */
export const uploadparkphotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('Id', photoInfo.Id);
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtPark/uploadparkphotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 修改停车场附件
 */
export const updateparkphotos = (photoInfo) => {
  let data = new FormData();
  photoInfo.file.map((item, index) => {
    data.append('file', item);
    data.append('sorts', photoInfo.sorts[index]);
  });
  data.append('uuid', photoInfo.uuid);
  return axios.post('ZtPark/updateparkphotos.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 获取停车场附件
 */
export const getparkphotos = (val) => {
  return axios.get('ZtPark/getparkphotos.do', { params: val });
};

/**
 * 修理场信息查询
 */
export const getRepairShop = (val) => { return axios.get('ztRepair/searchPage.do', { params: val }); };

/**
 * 新增修理场
 */
export const addRepair = (val) => { return axios.post('ztRepair/insert.do', val); };

/**
 * 获取单个修理场信息
 */
export const getRepairShopinfo = (val) => { return axios.get('ztRepair/searchOne.do', { params: val }); };

/**
 * 修改修理场
 */
export const updateRepair = (val) => { return axios.post('ztRepair/update.do', val); };

/**
 * 删除修理场
 */
export const deleteRepair = (val) => { return axios.get('ztRepair/delete.do', { params: val }); };

/**
 * 查询趟程明细表
 */
export const getZtTrip = (val) => { return axios.get('ZtTripReport/getZtTrip.do', { params: val }); };

/**
 * 查询电子运单统计
 */
export const getSlagCarElectronicWaybillDetail = (val) => { return axios.get('/electronicWaybillReport/getSlagCarElectronicWaybillDetail.do', { params: val }); };

export const getSlagCarElectronicWaybillDetailById = (val) => { return axios.get('/electronicWaybillReport/getSlagCarElectronicWaybillDetailById.do', { params: val }); };

/**
 * 查询报警
 */
export const getGindoverloadalarm = (val) => { return axios.get('overloadalarm/findoverloadalarm.do', { params: val }); };
/*
 * 加油站信息查询
 */
export const getGasStation = (val) => { return axios.get('ztGas/searchPage.do', { params: val }); };

/**
 * 新增加油站
 */
export const addGas = (val) => { return axios.post('ztGas/insert.do', val); };

/**
 * 获取单个加油站信息
 */
export const getGasInfo = (val) => { return axios.get('ztGas/searchOne.do', { params: val }); };

/**
 * 修改加油站
 */
export const updateGas = (val) => { return axios.post('ztGas/update.do', val); };

/**
 * 删除加油站
 */
export const deleteGas = (val) => { return axios.get('ztGas/delete.do', { params: val }); };


/**
 * 上传excle信息
 * @param {FormData} formData 
 * @param {JSON} config 
 */
export const uploadSiteFile = (type, formData, config) => {
  const url = {
    'site': 'ZtSite/importztsite.do',
    'consump': 'ZtEarth/importztearth.do',
    'parking': 'ZtPark/importztpark.do',
  }[type];
  return axios.post(url, formData, config);
};
/**
 * 查看是否有导入缓存
 */
export const getSiteTemp = (type, val) => {
  const url = {
    'site': 'ZtSite/initztsiteTemp.do',
    'consump': 'ZtEarth/initztearthTemp.do',
    'parking': 'ZtPark/initztparkTemp.do',
  }[type];
  return axios.get(url, { params: val });
};

/**
 * 删除导入工地缓存
 */
export const deleteSiteTemp = (type, val) => {
  const url = {
    'site': 'ZtSite/deleteztsiteTemp.do',
    'consump': 'ZtEarth/deleteztearthTemp.do',
    'parking': 'ZtPark/deleteztparkTemp.do',
  }[type];
  return axios.get(url, { params: val });
};

/**
 * 获取上传的信息
 */
export const getListztsiteTemp = (type, val) => {
  const url = {
    site: 'ZtSite/listztsiteTemp.do',
    consump: 'ZtEarth/listztearthTemp.do',
    parking: 'ZtPark/listztparkTemp.do'
  }[type];
  return axios.get(url, { params: val });
};

/**
 * 保存上传的工地信息
 */
export const saveztsiteTemp = (val) => {
  return axios.get('ZtSite/saveztsiteTemp.do', { params: val });
};

/**
 * 修改上传的工地信息
 */
export const updateztsiteTemp = (val) => {
  return axios.get('ZtSite/updateztsiteTemp.do', { params: val });
};

/**
 * 修改上传的消纳场信息
 */
export const updateztearthTemp = (val) => {
  return axios.get('ZtEarth/updateztearthTemp.do', { params: val });
};

/**
 * 保存上传的消纳场信息
 */
export const saveztearthTemp = (val) => {
  return axios.get('ZtEarth/saveztearthTemp.do', { params: val });
};

/** 修改上传的停车场信息 */
export const updateztparkTemp = (val) => {
  return axios.get('ZtPark/updateztparkTemp.do', { params: val });
};
/**
 * 保存上传的停车场信息
 */
export const saveztparkTemp = (val) => {
  return axios.get('ZtPark/saveztparkTemp.do', { params: val });
};

/**
 * 电子运单下发模板删除接口
 * @param {*} params.id 
 */
export const deleteSlagCarElectronicWaybill = (params) => axios.get('electronicWaybillReport/deleteSlagCarElectronicWaybill.do', { params });

/**
 * 电子运单下发模板修改接口
 * @param {*} params.electronicWaybill
 */
export const updateSlagCarElectronicWaybill = (params) => axios.get('electronicWaybillReport/updateSlagCarElectronicWaybill.do', { params });
/**
 * 电子运单下发模板新增接口
 * @param {*} params.electronicWaybill 
 */
export const addSlagCarElectronicWaybill = (params) => axios.get('electronicWaybillReport/addSlagCarElectronicWaybill.do', { params });
/**
 * 电子运单下发模板查询接口
 * @param {*} params.startTime 
 * @param {*} params.endTime 
 * @param {*} params.pageNumber 
 * @param {*} params.pageSize 
 */
export const getSlagCarElectronicWaybill = (params) => axios.get('electronicWaybillReport/getSlagCarElectronicWaybill.do', { params });

/**
 * 消纳场收土量统计
 */
export const getEarthSoilReport = (params) => axios.get('ztearthreport/getZtEarthReport.do', { params });

/**
 * 工地出土量统计
 */
export const getSiteSoilReport = (params) => axios.get('ztsitereport/getZtSiteReport.do', { params });

/**
 * 查询渣土车运输申请单
 */
export const getApplyLists = (params) => axios.get('ztTransportApply/searchApplyPage.do', { params });

/**
 * 获取渣土车运输申请单ID
 */
export const getApplyNumber = (params) => axios.get('ztTransportApply/getApplyNumber.do', { params });

/**
 * 获取运输订单附件
 */
export const getTransImages = (params) => axios.get('ztTransportApply/getImages.do', { params });

/**
 * 删除渣土运输申请单
 */
export const deleteTransport = (params) => axios.get('ztTransportApply/deleteTransportApply.do', { params });

/**
 * 新增渣土运输单
 */
export const addTransport = (params) => axios.post('ztTransportApply/insertTransportApply.do', params);

/**
 * 修改渣土运输单
 */
export const updateTransport = (params) => axios.post('ztTransportApply/updateTransportApply.do', params);

/**
 * 运输单附件上传
 */
export const uploadTransphotos = (photoInfo) => {
  let data = new FormData();
  data.append('id', photoInfo.id);

  photoInfo.files.forEach((item, index) => {
    data.append('files', item.file);
    data.append('sorts', item.type);
  });

  return axios.post('ztTransportApply/uploadImages.do', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/**
 * 获取消息通知
 */
export const getTransportNotice = (params) => axios.get('ztTransportApply/getNotice.do', { params });

/**
 * 根据id获取运输单
 */
export const getTransportById = (params) => axios.get('ztTransportApply/getById.do', { params });

