/**
 * 保存文件
 */

export const saveAsFile = (src, filename) => {
  try {

    let a = document.createElement("a");
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = src;
    a.target = "_blank";
    a.download = filename || src.substr(src.lastIndexOf("/"));
    a.click();
    a.remove();
  } catch (err) {
    console.error(err);
  }
};