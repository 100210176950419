<template>
  <ResizeObserver @resize="handleWrapperResize">
    <div :style="outerStyle"
      class="SplitLayout">
      <!-- 面板1 -->
      <div ref="mainRef"
        :style="mainStyle"
        class="SplitMain">
        <div v-show="!isMinimize"
          :style="firstOuterStyle"
          class="SplitFirst">
          <slot name="first"></slot>
        </div>

        <!-- 分割栏 -->
        <SplitTrigger ref="triggerRef"
          v-model="sideOffsetPtg"
          :direction="direction"
          :defaultOffsetPtg="defaultSideOffsetPtg"
          :triggerWidth="triggerWidth"
          :includeMaximize="canMaximize"
          @trigger="onTrigger"
          :showButton="showButton"></SplitTrigger>

        <!-- 面板2 -->
        <div v-show="!isMaximize"
          class="SplitSecond">
          <slot name="second"></slot>
        </div>
      </div>
    </div>
  </ResizeObserver>
</template>

<script>

/**
 * 分隔面板
 */

import ResizeObserver from '@/components/ResizeObserver';
import SplitTrigger from './SplitTrigger.vue';

const FlexDriecton = {
  Row: 'row',
  RowReverse: 'row-reverse',
  Column: 'column',
  ColumnReverse: 'column-reverse',
};

export default {
  name: 'SplitLayout',
  components: {
    SplitTrigger,
    ResizeObserver,
  },
  props: {
    // 是否可以最大化
    canMaximize: {
      type: Boolean,
      default: false,
    },

    // flex-direction
    direction: {
      type: String,
      default: 'row',
      validator(val) {
        return Object.values(FlexDriecton).includes(val);
      }
    },
    sideOffset: {
      type: [Number, String],
      default: 0.2,
    },
    padding: {
      type: Number,
      default: 10,
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultSideOffsetPtg: 0,
      sideOffsetPtg: 0,
      triggerWidth: 10,
    };
  },
  computed: {
    isColumn() {
      const { direction } = this;
      return ['column', 'column-reverse'].includes(direction);
    },
    isMinimize() {
      const { sideOffsetPtg } = this;
      this.$emit('change', sideOffsetPtg == 0 ? 1 : sideOffsetPtg != 1 ? 2 : 3)
      return sideOffsetPtg === 0;
    },
    isMaximize() {
      const { sideOffsetPtg } = this;
      return sideOffsetPtg === 1;
    },
    // 外部样式
    outerStyle() {
      const { padding } = this;
      return {
        padding: `${ padding }px`,
      };
    },
    mainStyle() {
      let { direction } = this;
      return {
        'flex-direction': direction,
      };
    },
    firstOuterStyle() {
      const { sideOffsetPtg, triggerWidth } = this;

      return {
        'flex-basis': `${ sideOffsetPtg * 100 }%`, // `calc(${sideOffsetPtg}% - ${triggerWidth / 2}px)`,
      };
    },

  },
  watch: {
    sideOffset() {
      this.toSideOffsetPtg();
    }
  },
  mounted() {
    this.toSideOffsetPtg();
  },
  methods: {
    toSideOffsetPtg() {
      let { sideOffset } = this;
      if (sideOffset > 1) {
        const maxOffset = this.getMaxOffset();
        sideOffset = (parseFloat(sideOffset) / maxOffset);
      }

      sideOffset = parseFloat(sideOffset.toFixed(6));

      this.sideOffsetPtg = sideOffset;
      this.defaultSideOffsetPtg = sideOffset;
    },
    handleWrapperResize() {
      const { triggerRef } = this.$refs;
      triggerRef?.refresh();
    },
    onTrigger(offsetPtg) {
      this.$emit('trigger', offsetPtg);
    },
    getMaxOffset() {
      const { isColumn } = this;
      const { mainRef } = this.$refs;
      if (!mainRef) return Number.MAX_VALUE;

      const { width, height } = mainRef?.getBoundingClientRect();
      // 容器宽度/高度 - triggerWidth
      return (isColumn ? height : width);
    },
  }
}
</script>

<style lang="scss" scoped>
.SplitLayout {
  width: 100%;
  height: 100%;
}
.SplitMain {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.SplitFirst {
  // background: #fff;
  overflow: hidden;
}
.SplitSecond {
  flex: 1;
  // background: #fff;
  //   overflow: hidden;
  overflow: auto;
}
</style>