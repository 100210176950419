<template>
  <div class="select-avatar-wrapper">
    <a v-if="!imgsrc">{{placeholder}}</a>
    <img v-if="imgsrc"
      :title="imgFilename"
      :src="imgsrc" />
    <div class="avatar-cover">
      <i size="24"
        class="el-icon-plus"
        title="选择图片"
        @click="onSelectClick"
        v-if="hasChange"></i>
      <i v-show="imgsrc"
        size="24"
        class="el-icon-view"
        title="查看图片"
        @click="onViewImg"></i>
      <i v-show="imgsrc"
        size="24"
        class="el-icon-delete"
        title="删除图片"
        @click="onDelete($event)"></i>
      <input :id="inputId"
        v-show="false"
        type="file"
        :accept="photoType"
        @change="onInputChange" />
    </div>
  </div>
</template>

<script>
/**
 * 选择图片
 * 驾驶员资料会用到
 */
import { randomStr } from "@/utils/random";

export default {
  name: "SelectAvatar",
  props: {
    src: {
      type: String,
      default: "",
      required: false,
    },
    placeholder: {
      type: String,
      default: "选择图片",
      required: false,
    },
    extra: {
      type: [Object, String, Number, Boolean],
      default: null,
      required: false,
    },
    photoType: {
      type: String,
      default: '.jpg, .jpeg'
    },
    IS_LOGO: {
      type: Boolean,
      default: false
    },
    hasChange: {
      type: Boolean,
      default: true
    },
    avatarIndex: {
      type: Number
    }
  },
  data() {
    return {
      imgsrc: "",
      imgFilename: "",
      inputId: randomStr(1),
      imgId: randomStr(1)
    };
  },
  watch: {
    src: {
      immediate: true,
      handler(val) {
        this.imgsrc = val;
      },
    },
  },
  methods: {
    onInputChange(e) {
      console.log("e", e);
      const file = e.target.files[0];
      if (this.IS_LOGO) {
        if (!file.name.endsWith(".png")) {
          return this.$message.warning("请选择PNG格式的图片");
        }
      } else {
        if (
          !(file.name.endsWith(".jpg") || file.name.endsWith(".jpeg")) ||
          file.size / 1024 > 200
        ) {
          return this.$message.warning("请选择小于200KB的JPG或JPEG格式的图片");
        }
      }
      this.imgFilename = file.name;
      this.imgsrc = URL.createObjectURL(file);
      this.$emit("on-change", file, this.extra);
    },
    onSelectClick() {
      document.getElementById(this.inputId).click();
    },

    onDelete(e) {
     
      document.getElementById(this.inputId).value = '';
      this.imgsrc = '';
      this.imgFilename = "";
       this.$emit("groupDelete", this.avatarIndex);
       console.log("this.avatarIndex", this.avatarIndex);
    },
    onViewImg() {
      this.$alert(
        `<img style="width: 100%;height: 100%;" src=${ this.imgsrc } />`,
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.select-avatar-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dcdee2;
  border-radius: 4px;
  text-align: center;
  &:hover {
    border: 1px dashed #2d8cf0;
    .avatar-cover {
      display: flex;
    }
  }
  & > a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > img {
    width: 100%;
    height: 100%;
  }
  .avatar-cover {
    display: none;
    position: absolute;
    height: 32px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: center;
    align-items: center;
    & > i {
      cursor: pointer;
      color: white;
      margin: 0 5px;
    }
  }
}
</style>

