<template>
  <el-tree
    ref="tree"
    :data="permTree"
    show-checkbox
    node-key="permId"
    default-expand-all
    :check-strictly="false"
    :default-checked-keys="defaultCheckeds"
    :props="defaultProps"
    @check-change="nodeCheck"
  ></el-tree>
</template>

<script>
import { roterData } from "@/config/roterData";
import { mapState } from "vuex";
import { findUserAuthority } from "@/api/getManagementData.js";
import { filterTree } from '@/utils/treeHelper';


export default {
  name: "perTree",
  props: {

    // 上级用户Id
    parentId: {
      type: Number,
      default: null,
    },
    // 用户Id, 大于0则修改权限
    userId: {
      type: Number,
      default: 0,
    },

  },
  data() {
    return {
      permTree: [],
      defaultCheckeds: [
        'M01'
      ], // 默认选中的节点

      defaultProps: {
        children: "children",
        label: "label",
      },

      // 所有选中节点keys
      selectedKeys: [],
    };
  },
  watch: {
    userId: {
      immediate: true,
      async handler(val) {
        await this.$nextTick();
        this.loadUserPerms();
      }
    }
  },
  computed: {
    ...mapState(['userPermissions']),
  },
  methods: {

    /**
     * 加载用户权限
     */
    async loadUserPerms() {
      this.permTree = [];
      this.defaultCheckeds = [];
      const { userId, parentId, userPermissions } = this;

      if (!(userId !== null && parentId !== null)) return;

      // 是否是新增
      const isAddFlag = !(userId > 0);

      // 上级权限列表, 默认为当前登录用户的权限
      let superiorPerms = userPermissions;

      if (parentId > 1) {
        // 获取上级用户权限
        const perms = await findUserAuthority(parentId);
        // 显示的上级用户权限:  上级用户权限和当前登录用户权限交集
        superiorPerms = userPermissions.filter(perm => perms.includes(perm));
      }

      // NOET: M01: 实时定位必有
      if (!superiorPerms.includes('M01')) {
        superiorPerms.push('M01');
      }

      const showPerms = filterTree(roterData, (obj) => superiorPerms.includes(obj.permId));

      this.permTree = showPerms;

      // 新增默认勾选所有
      let checkedKeys = [...superiorPerms];
      if (!isAddFlag) {
        // 修改, 勾选用户的权限
        const perms = await findUserAuthority(userId);

        // NOET: M01: 实时定位必有
        if (!perms.includes('M01')) {
          perms.push('M01');
        }

        checkedKeys = [...perms];
      }

      await this.$nextTick();
      this.setCheckKeys(checkedKeys);
    },

    setCheckKeys(keys) {
      const { tree } = this.$refs;
      if (!tree) return;
      // tree.setCheckedKeys(keys);
      keys
        .forEach(key => {
          tree.setChecked(key, true, false);
        });
    },

    /**
     *  复选框勾选事件 
     * node：该节点所对应的对象
     * check：节点本身是否被选中
     */
    nodeCheck(node, check) {
      const { tree } = this.$refs;
      if (!tree) return;

      // if (node.children) {
      //   node
      //     .children
      //     .forEach(subNode => {
      //       tree.setChecked(subNode.permId, check);
      //     });
      // }

      this.$emit('on-check', { node, check });
    },

    getCheckeds() {
      const checkeds = this.$refs.tree.getCheckedNodes(false, true);
      return checkeds.map(obj => obj.permId);
    },
  },
};
</script>