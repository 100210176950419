<template>
  <el-container class="UserManagement-wrap">
    <el-header height="75px">
      <div class="access-info">
        <label>
          登录账户：
          <span>{{ customer.name }}</span>
        </label>
        <label>
          类型：
          <span>
            {{ ["我的客户", "监控客户"][customer.accountType] || "--" }}
          </span>
        </label>
        <label>
          设备数量：
          <span>
            <div class="state-dot" style="background: #1890ff"></div>
            全部：{{ customer.allVehicleCount || "--" }}台 &nbsp;&nbsp;&nbsp;
            <div class="state-dot" style="background: #1edf8c"></div>
            在线：{{ customer.allOnlineCount || "--" }}台 &nbsp;&nbsp;&nbsp;
            <div class="state-dot" style="background: #ff4640"></div>
            离线：{{ customer.allOffLineCount || "--" }}台
          </span>
        </label>
      </div>
      <div class="access-info">
        <label>
          客户名称：
          <span>{{ customer.corpName || "--" }}</span>
        </label>
        <label>
          客户代码：
          <span>{{ customer.customCode || "--" }}</span>
        </label>
        <label>
          电话：
          <span>{{ customer.phone || "--" }}</span>
        </label>
      </div>
      <div class="user-btns">
        <el-input placeholder="请输入客户名称/账号" @change="handleChangeUser" v-model="userInput" clearable>
        </el-input>
        <el-button type="primary" plain size="small" @click="inputName = userInput,getUserGroups(userInfo.userId)">搜索</el-button>
        <el-button plain size="small" @click="inputName = '',userInput = '',getUserGroups(userInfo.userId)">重置</el-button>
        <!-- <el-button type="primary" @click="addNewUser()">新增用户</el-button> -->
        <Export
          :disabled="!tableData.length"
          title="用户信息"
          exportUrl="exportReport/exportExcel.do"
          :params="{ userId: this.customer.userId, type: 19 }"
        ></Export>
      </div>
    </el-header>
    <el-container style="padding: 0">
      <Split direction="row" :sideOffset="0.18">
        <template #first>
          <div class="user-tree">
            <UserTree
              v-model="selectedData"
              :userId="customer.userId"
              ref="userTree"
              :showButton="true"
              @checkChange="checkChange"
              @nodeClick="selectVGroup"
            >
              <template v-slot:action="{ node }">
                <div>
                  <!-- <span @click="addNewUser(node)">
                    <Iconfont
                      :size="12"
                      name="icon-fenzuzengjia"
                      class="iconClass"
                    ></Iconfont>
                  </span> -->
                </div>
              </template>
            </UserTree>
          </div>
        </template>
        <template #second>
          <el-table
            :data="tableData"
            :loading="tableLoading"
            size="small"
            class="el-table-style"
            :header-cell-style="{
              background: '#f5f5f5',
              color: '#262626',
              'font-weight': 'bold',
            }"
          >
            <el-table-column
              prop="name"
              label="登录账户"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="corpName"
              label="客户名称"
              width="120"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="money"
              label="授权币"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="parentName"
              label="上级账户"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="parentCorp"
              label="上级公司名"
              width="160"
              align="center"
            ></el-table-column>
            <!-- <el-table-column prop="ip" label="IP" width="160" align="center"></el-table-column> -->
            <!-- <el-table-column
              prop="customKey"
              label="密钥"
              width="160"
              align="center"
            ></el-table-column>-->
            <el-table-column
              prop="validitydate"
              label="有效期"
              width="200"
              align="center"
            >
              <template v-slot="{ row }">
                {{ row.validitydate | formatDate }}
              </template>
            </el-table-column>

            <el-table-column
              label="操作"
              min-width="220"
              fixed="right"
              v-slot="{ row }"
            >
              <template v-if="row.userId !== customer.userId">
                <!-- <el-button @click="addNewUser(row)" type="text"
                  >新增下级</el-button
                >
                <el-button @click="updateUser(row)" type="text">修改</el-button>
                <el-button
                  @click="
                    removeUser({
                      row,
                      children: tableData.filter(
                        (p) => p.parentId === row.userId
                      ),
                    })
                  "
                  type="text"
                  >删除</el-button
                > -->
                <el-button @click="bindClick(row)" type="text">绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            :current-page="tablePage.pageCurrent"
            :page-size="tablePage.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="tablePage.total"
            @current-change="changePage"
            style="text-align: center; margin: 10px 10px 0 10px"
          ></el-pagination>
        </template>
      </Split>
    </el-container>
    <modal-edit-user
      v-model="userInfoModal.modal"
      :inputParams="userInfoModal.params"
      @on-ok="onEditUserOk"
      @on-cancel="onCancelEditUser"
    ></modal-edit-user>
    <el-dialog
      v-model="bindModal.modal"
      :visible="bindModal.modal"
      @close="onModalCancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      destroy-on-close
    >
      <div slot="title" class="header-title">
        <span>绑定车组</span>
      </div>
      <bind-vehicle
        :parentUser="bindModal.parentUser"
        :userId="bindModal.userId + ''"
        @on-cancel="onModalCancel"
      ></bind-vehicle>
    </el-dialog>
  </el-container>
</template>

<script>
import Split from "@/components/Split";
import UserTree from "@/components/UserTree";
import BindVehicle from "./BindVehicle"; //1
import {
  getManageUserInfo,
  addUpVehicleStatusCount,
  delUser,
} from "@/api/getManagementData.js";
import Export from "@/components/Export";
import FoldButton from "@/components/FoldButton";
import { mapState } from "vuex";
import moment from "dayjs";
import ModalEditUser from "./ModalEditUserInfo";
import { parseTime } from "@/utils/formatDate";
export default {
  // `name`与路由保持一致
  name: "UserManagement",

  components: {
    Export,
    FoldButton,
    UserTree,
    ModalEditUser,
    BindVehicle,
    Split,
  },
  filters: {
    formatDate(val) {
      return val ? parseTime(val) : "无";
    },
  },
  data() {
    return {
      userId: null,
      customer: { userId: 0 },
      zNodes: [],
      searchUser: "", //搜索input
      searchUserDataList: [], //搜索弹窗列表
      selectedData: {}, //选中数据
      visible: true, //搜索弹窗
      treeData: {
        //树形图参数
        treeId: "usertree",
        parentIf: true,
        hoverBtnIf: true,
        checkIf: false,
      },
      data: [], //table数据
      tableLoading: true,
      bindModal: {
        modal: false,
        userId: 0,
        parentUser: { userId: 0 },
      },
      tablePage: {
        //分页
        pageSizeOpts: [20, 50, 100],
        pageCurrent: 1,
        pageSize: 20,
        total: 0,
      },
      userInfoModal: {
        modal: false,
        params: {
          userId: 0,
          parentId: 0,
          parentName: "",
          type: 0, // 0: 新增  1：修改
        },
      },
      editUserData: {
        addIf: true,
        name: "",
        password: "",
        corpName: "",
        parentId: 0,
        parentName: "",
        groupIds: "",
        accountType: 0,
        checkGroup: 0,
        phone: "",
        customCode: "",
        parentCheckGroup: 0,
        userId: 0,
        funcId: "",
      },
      searchUserSelected: [],

      expandSide: true, //收缩点击
      userInput: '',
      inputName: ''
    };
  },
  computed: {
    ...mapState(["userInfo"]),

    tableData: function () {
      var minIndex = (this.tablePage.pageCurrent - 1) * this.tablePage.pageSize;
      var maxIndex = this.tablePage.pageCurrent * this.tablePage.pageSize;
      let TableArr = this.data.filter((p, i) => {
        if (i >= minIndex && i < maxIndex) {
          return true;
        }
        return false;
      });
      return TableArr;
    },
  },
  watch: {
    userArray: {
      deep: true,
      handler: function (val, oldval) {
        this.zNodes = this.userArray;
        this.zNodes.forEach(function (val) {
          val.type = ["我的客户", "监控客户"][val.accountType];
        });
        this.data = this.userArray;
        this.tableLoading = false;
      },
    },
  },
  // activated() {
  created() {
    this.onlineCount();
    this.getUserGroups(this.userInfo.userId);
  },
  beforeMount() {
    if (!this.userInfo) {
      console.log("signout");
    }
    this.customer = this.userInfo;
  },
  mounted() {
    // this.onlineCount();
  },
  methods: {
    // hasPerms,
    // ...mapActions([
    //   'updateUserArray'
    // ]),

    checkChange(node, checked) {
      console.log(node, checked);
    },
    getDate(row) {
      return moment(new Date(row.validitydate)).format("yyyy-MM-dd HH:mm:ss");
    },
    bindClick(row) {
      this.bindModal.userId = row.userId;
      this.bindModal.parentUser.userId = row.parentId;
      this.bindModal.modal = true;
    },
    onModalCancel() {
      this.bindModal.modal = false;
      this.bindModal.userId = 0;
      this.bindModal.parentUser.userId = 0;
    },

    //新增用户
    addNewUser(parentUser = null) {
      this.userInfoModal.params.type = 0; // 类型

      this.userInfoModal.params.userId = 0;
      this.userInfoModal.params.parentId = -1;

      if (parentUser) {
        // 新增下级用户
        const { userId, parentId } = parentUser;

        this.userInfoModal.params.userId = userId;
        this.userInfoModal.params.parentId = parentId;
      }

      this.userInfoModal.params.parentUser = parentUser;

      this.userInfoModal.modal = true;
    },

    updateUser(userInfo) {
      //修改用户
      if (!userInfo) return;
      this.userInfoModal.params.type = 1;
      this.userInfoModal.params.parentUser = null;
      this.userInfoModal.params.userId = userInfo.userId;
      this.userInfoModal.params.parentId = userInfo.parentId;
      this.userInfoModal.modal = true;
    },
    onEditUserOk(userInfo) {
      if (userInfo && userInfo.userId) {
        // 编辑用户
        this.getUserGroups(userInfo.userId);
        setTimeout(() => {
          this.selectedData = {
            userId: userInfo.userId,
          };
        }, 400);
      }
      this.updateUserTree();
      this.getUserGroups(this.userInfo.userId);
      this.onCancelEditUser();
    },
    updateUserTree() {
      this.$refs.userTree.reload(this.userInfo.userId);
    },
    onCancelEditUser() {
      this.editUserData.parentId = 0;
      this.userInfoModal.userId = 0;
      this.userInfoModal.parentUser = null;
      this.userInfoModal.modal = false;
    },
    // 获取当前用户车辆状态(在线/离线)
    onlineCount() {
      addUpVehicleStatusCount().then((res) => {
        if (res.flag == 1) {
          const { allOffLineCount, allOnlineCount, allVehicleCount } = res.obj;
          this.customer = {
            ...this.customer,
            allOffLineCount,
            allOnlineCount,
            allVehicleCount,
          };
        }
      });
    },
    // 请求用户树数据
    getUserGroupsArray: function () {
      if (this.userArray && this.userArray.length > 0) {
        this.zNodes = this.userArray;
        this.zNodes.forEach(function (val) {
          val.type = ["我的客户", "监控客户"][val.accountType];
        });
        this.data = this.userArray;
        this.tableLoading = false;
      } else {
        this.updateUserArray();
      }
    },
    // 请求用户数据
    getUserGroups(id, type) {
      getManageUserInfo({
        userId: id,
        platform: "PC",
        name: this.inputName,
        _qt: new Date().getTime(),
      }).then((res) => {
        if (res.flag == 1) {
          // console.log(res)
          // 判断是否是第一次，讲所有用户组放在zNodes中
          if (type == "first") {
            this.zNodes = res.obj.userList;
            this.zNodes.forEach(function (val) {
              val.type = ["我的客户", "监控客户"][val.accountType];
            });
          }
          this.tablePage.pageCurrent = 1;
          this.tablePage.total = res.obj.userList.length;
          this.data = res.obj.userList;
          this.tableLoading = false;
        } else {
          this.tablePage.total = 0;
          this.data = [];
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 模糊搜索
    searchUserData() {
      this.searchUserSelected = [];
      if (this.searchUser !== "") {
        this.visible = false;
        let filterList = this.zNodes.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.searchUser.toLowerCase()) >
              -1 ||
            item.corpName.toLowerCase().indexOf(this.searchUser.toLowerCase()) >
              -1
        );
        // console.log(_this.zNodes)
        this.searchUserDataList = filterList.filter((item, index) => index < 8);
        if (this.searchUserDataList.length <= 0) {
          this.visible = true;
        }
        for (let i = 0; i < this.searchUserDataList.length; i++) {
          if (i == 0) {
            this.searchUserSelected.push(true);
          } else {
            this.searchUserSelected.push(false);
          }
        }
      } else {
        this.visible = true;
        this.searchUserDataList = [];
      }
    },
    keyDown() {
      if (!this.visible) {
        let arrIndex = this.searchUserSelected.findIndex((p) => p);
        this.$set(this.searchUserSelected, arrIndex, false);
        if (arrIndex >= this.searchUserSelected.length - 1) {
          this.$set(this.searchUserSelected, 0, true);
        } else {
          this.$set(this.searchUserSelected, arrIndex + 1, true);
        }
      }
    },
    keyUp() {
      if (!this.visible) {
        let arrIndex = this.searchUserSelected.findIndex((p) => p);
        this.$set(this.searchUserSelected, arrIndex, false);
        if (arrIndex <= 0) {
          this.$set(
            this.searchUserSelected,
            this.searchUserSelected.length - 1,
            true
          );
        } else {
          this.$set(this.searchUserSelected, arrIndex - 1, true);
        }
      }
    },
    handleChangeUser(e,v) {
      console.log(e),v;
      // inputName = userInput,getUserGroups(userInfo.userId)
    },
    keyEnter: function () {
      // console.log('keyEnter')
      let arrIndex = this.searchUserSelected.findIndex((p) => p);
      this.selectedUser(this.searchUserDataList[arrIndex]);
    },
    // 搜索弹窗取消
    onCancel: function () {
      this.visible = true;
    },
    // 搜索弹窗选中
    selectedUser: function (val) {
      // console.log(val)

      this.visible = true;
      // this.selectedData = val;
      this.selectedData = {
        userId: val.userId,
      };

      this.searchUser = val.corpName;
      this.getUserGroups(val.userId);
    },
    // 树形图单击事件，查询table数据
    selectVGroup: function (manageWay) {
      // if (manageWay.type == "click") {
      this.getUserGroups(manageWay.userId);
      // } else {
      //   this.data = [];
      // }
    },
    // 树形图hover单击事件
    manage(manageWay) {
      // 绑定车组
      if (manageWay.index == 0) {
        this.bindModal.userId = manageWay.data.userId;
        this.bindModal.parentUser.userId = manageWay.data.parentId;
        this.bindModal.modal = true;
      }
      //删除
      if (manageWay.index == 1) {
        this.removeUser(manageWay.data);
      }
      //编辑用户
      if (manageWay.index == 2) {
        this.updateUser(manageWay.data);
      }
      //添加用户
      if (manageWay.index == 3) {
        this.addNewUser(manageWay.data);
      }
    },
    // 确认删除
    removeUser(manageWay) {
      this.$confirm(
        "是否删除该用户？删除该用户账号内车币将清空且不可退！",
        "删除",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          setTimeout(() => {
            this.removeUserData(manageWay);
          }, 400);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 判断是否有子用户
    removeUserData(manageWay) {
      // console.log(manageWay)
      if (manageWay.children && manageWay.children.length > 0) {
        this.$confirm("该用户下还有子用户,是否继续删除该用户？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.doRemove(1, manageWay.row.userId, manageWay.row.parentId);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.doRemove(0, manageWay.row.userId, manageWay.row.parentId);
      }
      //   this.$Modal.confirm({
      //     title: "信息",
      //     content: "<p>该用户下还有子用户,是否继续删除该用户？</p>",
      //     loading: true,
      //     onOk: () => {
      //       this.doRemove(1, manageWay.userId, manageWay.parentId);
      //     },
      //   });
      // } else {
      //   this.doRemove(0, manageWay.userId, manageWay.parentId);
      // }
    },
    // 执行删除用户操作
    doRemove(type, id, parentId) {
      // console.log(id)
      delUser({ userId: id }).then((res) => {
        // console.log(res)
        if (res.flag == 1) {
          this.getUserGroups(parentId);
          this.updateUserTree();
          setTimeout(() => {
            this.selectedData = {
              userId: parentId,
            };
          }, 400);
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    // 分页  改变每页数量
    pageSizeChange(pageSize) {
      this.tablePage.pageSize = pageSize;
      this.changePage(1);
    },
    // 分页，改变页数
    changePage(current) {
      this.tablePage.pageCurrent = current;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  position: relative;
  background-color: #ffffff;
  color: #000000;
  // margin: 0 0 7px 0;
  // padding: 10px 15px;
  box-sizing: border-box;
}
.el-main {
  padding: 0;
}
.el-pagination {
  text-align: center;
  margin: 10px 0;
}
.access-info {
  display: flex;
  align-items: center;
  & > label {
    line-height: 37px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    width: auto;
    margin: 0 60px 0 0;
    font-family: ".PingFang SC, .PingFang SC-Medium";
  }
  span {
    font-weight: 500;
  }
}
.user-tree {
  height: 82vh;
  width: 100%;
  overflow: auto;
}
.user-btns {
  position: absolute;
  display: flex;
  right: 15px;
  bottom: 15px;
}
.state-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 3px 2px 3px;
}
.el-table-style {
  border: 1px solid #ddd;
}
.header-title {
  background: #212d3e;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
::v-deep .el-dialog__header {
  padding: 0;
}
.el-container {
  height: 100%;
  width: 100%;
  background: #fbfafa;
  // padding: 12px 0;
  box-sizing: border-box;
}
.el-aside {
  background: #eceeef;
}

.el-collapse {
  border: none;
  padding: 0 10px;
  background: #ffffff;
}
.iconClass {
  margin-right: 6px;
}
</style>
