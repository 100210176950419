<template>
  <div class="export-wrapper">
    <el-button
      size="small"
      :disabled="disabled"
      @click="exportData"
    >{{btnName || '导出/邮件'}}</el-button>
    <el-dialog
      width="30%"
      title="导出/发送邮件"
      :visible.sync="modal1"
      append-to-body
    >
      <el-radio-group v-model="sendType" style="margin: 0 0 10px 0;" @change="sendTypeChange">
        <el-radio label="0">直接导出</el-radio>
        <el-radio label="1">发送邮件</el-radio>
        <el-radio label="2">导出并发送邮件</el-radio>
      </el-radio-group>
      <div style="display:flex;margin-top: 10px;">
        <span style="width:150px;line-height: 32px;">邮箱发送地址：</span>
        <el-input placeholder="多个邮箱地址中间用逗号分隔。" v-model="sendMails" :disabled="DisSendMails"></el-input>
      </div>

      <div slot="footer">
        <el-button size="small" @click="oncancel">取消</el-button>
        <el-button size="small" type="primary" @click="onok">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { exportData, exportParams } from '@/api/exportReport-api';
import { saveAsFile } from '@/utils/saveAsFile';
import moment from 'dayjs';
import { mapState } from "vuex";

export default {
  name: "Export",
  props: {
    size: {
      type: String,
      default: "default"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isMiles: {
      default: true,
      type: Boolean
    },
    title: {
      default: "",
      type: String
    },
    state: {
      type: String,
      default: ''
    },
    exportUrl: {
      default: "",
      type: String
    },
    btnName: {
      default: '',
      type: String
    },
    exportDataType: {
      type: String,
      default: "1", // 1：video3G，2：统计服务
    },
    callback: {
      type: Function,
      default() {
        return () => {
          return true;
        };
      }
    },
    params: {
      default: {},
      type: Object
    }
  },
  data() {
    return {
      sendMails: "",
      sendType: "0",
      modal1: false,
      DisSendMails:true,
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    exportData(e) {
      if (this.isMiles) {
        this.modal1 = true;
      } else {
        this.exportDatas();
      }
    },
    oncancel() {
      this.sendMails=""
      this.modal1 = false;
    },
    onok() {
      let mails = /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      let reg = new RegExp("，","g");
      this.sendMails = this.sendMails.replace(reg,",");
      if(this.sendType * 1 == 1 || this.sendType * 1 == 2){//是否是发邮箱或发邮箱并导出
        if(this.sendMails.trim() ===""){
          this.$message.info("请输入邮箱地址");
          return
        };
        if(this.sendMails.indexOf(",") != -1 ){//邮箱字符串是否包含 ,
          let email = this.sendMails.split(",");
          for(let i in email){ //循环一次邮箱，判断是否有格式错误的
            if(email[i].trim() != ""){
              if(!mails.test(email[i].trim())){
                return this.$message.info("请输入正确的邮箱格式！");
              }
            }
          }
        }else{
          if(!mails.test(this.sendMails.trim())){
            return this.$message.info("请输入正确的邮箱格式！");
          }
        }
      }
      const params = {
        userId: this.userInfo.userId,
        ...this.params,
        state: +this.state,
        sendMails: this.sendMails,
        sendType: this.sendType * 1,
      };
      try {
        if (this.callback && this.callback(params)) {
          this.exportDatas();
        }
      } catch (err) {
        console.log(err)
      }
      this.sendMails = ""
      this.modal1 = false;
    },
    exportDatas() {
      const exportDataUrl = this.exportDataType * 1 === 1 ? exportData : exportParams;
      exportDataUrl(this.exportUrl, {
        userId: this.userInfo.userId,
        ...this.params,
        state: +this.state,
        sendMails: this.sendMails,
        sendType: this.sendType * 1,
      }).then(res => {
        if (res.msg) {
          this.$message.info(res.msg);
          return;
        }
        let urls = URL.createObjectURL(res);
        if (res.size != 0) {
          saveAsFile(
            urls,
            `${this.title}${moment(new Date()).format("YYYYMMDDHHmmss")}.xls`
          );
        } else {
          this.$message.info("发送邮件成功");
        }
      });
    },
    sendTypeChange(sendType){ //如果是直接导出则禁用输入框，不需要输入
      if(sendType ==0){
        this.DisSendMails = true;
      }else{
        this.DisSendMails = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.export-wrapper {
  margin: 0 5px;
}
</style>


