/**
 * 生成随机数
 */
export function randomNumber() {
  return Number(Math.random().toString().substr(2));
}

/**
 * 生成随机字符串
 */
export const randomStr = (notNum = 0) => {
  let str = Math.random()
    .toString(36)
    .substr(2);
  if (notNum) return str.replace(/\d/g, "");
  return str;
};

/**
 * 生成随机字符串
 * @param {boolean} onlyLetter - 只包含字母
 */
export const randomStr2 = (len, onlyLetter = false) => {
  let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  str += "abcdefghijklmnopqrstuvwxyz";
  if (!onlyLetter) {
    str += "0123456789-_!";
  }
  let tstr = "";
  for (let i = 0; i < len; ++i) {
    tstr += str[Math.floor(Math.random() * str.length)];
  }
  return tstr;
};
