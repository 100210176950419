<template>
  <div v-if="modal">
    <el-dialog
      :visible="modal"
      class="modal-edit"
      width="810px"
      @close="onModalChange"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      destroy-on-close
      top="10"
    >
      <span slot="title">{{ inputParams.type ? "修改用户" : "新增用户" }}</span>

      <div class="edit_content">
        <div class="content_left">
          <div class="edit-item">
            <label class="required">登录账号：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.name"
              :maxlength="20"
              @keyup.native="
                userInfoObj.name = userInfoObj.name.replace(
                  /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
                  ''
                )
              "
              placeholder="请输入您的账户"
            ></el-input>
          </div>

          <div class="edit-item">
            <label class="required">密码：</label>
            <div class="edit-item-body">
              <el-input
                :maxlength="20"
                v-model="userInfoObj.password"
                @keyup.native="
                  userInfoObj.password = userInfoObj.password.replace(
                    /[^\a-\z\A-\Z0-9]/g,
                    ''
                  )
                "
                placeholder="请输入您的密码"
              ></el-input>
              <el-button size="small" @click="onGenPwd">生成密码</el-button>
            </div>
          </div>
          

          <div class="edit-item">
            <label class="required">联系电话：</label>
            <el-input
              class="edit-item-body"
              :maxlength="11"
              v-model="userInfoObj.phone"
              @keyup.native="
                userInfoObj.phone = userInfoObj.phone.replace(/[^0-9]/g, '')
              "
              placeholder="请输入您的电话"
            ></el-input>
          </div>

          <div class="edit-item">
            <label class="required">客户名称：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.corpName"
              :maxlength="20"
              @keyup.native="
                userInfoObj.corpName = userInfoObj.corpName.replace(
                  /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
                  ''
                )
              "
              placeholder="请输入您的客户名称"
            ></el-input>
          </div>

          <div class="edit-item" v-show="isAddUser">
            <label class="required">绑定车组：</label>
            <div class="edit-item-body">
              <p v-if="userInfoObj.flag == 0">
                <span v-show="hasChoice">新账号请</span>
                <span v-show="!hasChoice">已绑定‘{{ num }}’个车组</span>
                <el-link
                  type="primary"
                  :underline="false"
                  style="margin: 0 3px"
                >
                  <span
                    @click="showBindGroupModal(true)"
                    v-show="hasChoice"
                  >选择绑定车组</span>
                  <span
                    @click="showBindGroupModal(false)"
                    v-show="!hasChoice"
                  >重新绑定车组</span>
                </el-link>
                <span v-show="hasChoice">
                  或
                  <el-link
                    type="primary"
                    @click="showModal()"
                    :underline="false"
                  >创建车组</el-link>
                </span>
              </p>
              <p v-else>
                <el-link
                  type="primary"
                  @click="showModal()"
                  :underline="false"
                >创建车组</el-link>
              </p>
            </div>
          </div>

          <div
            class="edit-item"
            v-if="userInfo.userId === 1 && userInfoObj.parentId === 1"
          >
            <label class="required">客户代码：</label>
            <el-input
              class="edit-item-body"
              :maxlength="20"
              v-model="userInfoObj.customCode"
              @keyup.native="
                userInfoObj.customCode = userInfoObj.customCode.replace(
                  /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
                  ''
                )
              "
              placeholder="请输入您的客户代码"
            ></el-input>
          </div>

          <div class="edit-item">
            <label>身份证号码：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.idCard"
              :maxlength="18"
              placeholder="请输入您的身份证号码"
            ></el-input>
          </div>

          <div
            class="edit-item"
            v-show="isAddUser & (userInfoObj.parentId == 1)"
          >
            <label>平台类型：</label>
            <el-select class="edit-item-body" v-model="userInfoObj.flag">
              <el-option :value="0" label="我的平台"></el-option>
              <el-option :value="1" label="其他平台"></el-option>
            </el-select>
          </div>

          <div class="edit-item">
            <label>上级账户：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.parentName"
              disabled
            ></el-input>
          </div>

          <div class="edit-item">
            <label>平台唯一代码：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.uniqueCode"
              @keyup.native="
                userInfoObj.uniqueCode = userInfoObj.uniqueCode.replace(
                  /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
                  ''
                )
              "
              placeholder="请输入您的平台唯一代码"
            ></el-input>
          </div>

          <div class="edit-item">
            <label>经营许可证：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.businessLicense"
              @keyup.native="
                userInfoObj.businessLicense =
                  userInfoObj.businessLicense.replace(
                    /[^\a-\z\A-\Z0-9\u4E00-\u9FA5,]/g,
                    ''
                  )
              "
              placeholder="请输入您的经营许可证（多个用逗号隔开）"
            ></el-input>
          </div>

          <div class="edit-item">
            <label>业户名称：</label>
            <el-input
              class="edit-item-body"
              v-model="userInfoObj.merchantName"
              placeholder="请输入您的业户名称（多个用逗号隔开）"
            ></el-input>
          </div>
        </div>

        <div class="content_right">
          <PermTree
            :parentId="permTreeParams.parentId"
            :userId="permTreeParams.userId"
            ref="permTreeRef"
          />
        </div>
      </div>

      <span slot="footer">
        <el-button :loading="vLoading" @click="onSaveUser" type="primary">保存</el-button>
        <el-button @click="onModalChange(false)">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="isAddUser"
      v-model="bindModal.modal"
      :visible="bindModal.modal"
      :styles="bindVehicleStyle"
      @close="onModalCancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      destroy-on-close
    >
      <div slot="title" class="header-title">
        <span>绑定车组</span>
      </div>
      <bind-vehicle
        :parentUser="bindModal.parentUser"
        :userData="bindModal.userId + ''"
        :checkOnly="true"
        type="1"
        @on-cancel="onModalCancel"
        @bindVehicleBtn="bindVehicleBtn"
      ></bind-vehicle>
    </el-dialog>

    <modal-car-group-info
      v-if="isAddUser"
      v-model="modalEditGroup"
      :userId="uuid"
      :parentId="editGroup.parentId"
      :parentName="editGroup.parentName"
      :groupId="editGroup.groupId"
      @on-ok="onOkCarGroup"
      @on-cancel="onCancelCarGroup"
    ></modal-car-group-info>
  </div>
</template>

<script>
import PermTree from "./PermTree";

import BindVehicle from "./BindVehicle";
import FilterVehicle from "./FilterVehicle";
import {
  getUserInfo,
  addClientUser,
  updateUser,
} from "@/api/getManagementData.js";
import { mapState, mapActions } from "vuex";

import * as util from "@/utils";
import { randomStr2 } from "@/utils/random";
import { phoneFun } from "@/utils/index";
import ModalCarGroupInfo from "@/components/ModalCarGroupInfo";

import { encrypt, decrypt } from "@/utils/aes-ecb";
import moment from "dayjs";
export default {
  name: "ModalEditUserInfo",
  model: {
    prop: "modal",
    event: "onModalChange",
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    inputParams: {
      //输入参数
      type: Object,
      default() {
        return { userId: 0, parentUser: null };
      },
    },
  },
  components: {
    PermTree,
    BindVehicle,
    FilterVehicle,
    ModalCarGroupInfo,
  },
  data() {
    return {
      vLoading: false,
      permTreeId: randomStr2(8, true),
      hasChoice: true,
      num: 0,
      bindModal: {
        modal: false,
        userId: 0,
        parentUser: {
          userId: 0,
        },
      },
      userInfoObj: this.initUserInfo(),
      parentPermList: [], //上级用户权限
      userPermList: [], //用户权限
      modalEditGroup: false,
      editGroup: {
        groupName: "",
        groupId: -1,
        parentName: "",
        parentId: -1,
        phone: "",
        groupType: "客车",
        remark: "",
      },
      groupVisible: false,
      editGroupSelectedId: 0,
      dailyPlayTime: 0, //每日播放时长 分钟
      minPlayTime: 0, //最小播放时长
      maxPlayTime: 60 * 24, //最大播放时长

      selectPermList: [], // 选择的权限
      uuid: 0, // 当前登录用户id
      permTreeParams: {
        userId: null,
        parentId: null,
      },
    };
  },

  mounted() {
    this.uuid = this.userInfo.userId;
  },
  computed: {
    ...mapState(["userInfo"]),

    isAddUser() {
      //是否是新增用户
      return !this.inputParams.type;
    },
    bindVehicleStyle() {
      let windowHeight = window.innerHeight;
      return {
        top: (windowHeight - 620) / 2 + "px",
      };
    },
  },
  watch: {
    modal: {
      async handler(val) {
        if (val) {
          this.vLoading = false;
          this.init();
          this.userInfoObj = this.initUserInfo();
          this.selectPermList = [];

          const { type, parentUser } = this.inputParams;

          if (!type && !parentUser) {
            this.userInfoObj.parentId = this.userInfo.userId;
          }

          if (parentUser) {
            // 新增下级用户

            // 自动填充上级用户
            this.userInfoObj.parentId = parentUser.userId;
            this.userInfoObj.parentName = parentUser.name;

            // 默认每日播放时长
            if (parentUser.dailyPlayTime) {
              this.minPlayTime = 1;
              this.maxPlayTime = ~~(parentUser.dailyPlayTime / 60);
              this.dailyPlayTime = this.maxPlayTime;
            }
          }

          if (type) {
            // 修改用户
            await this.getUser(this.inputParams.userId);
          }

          this.permTreeParams.userId = this.userInfoObj.userId;
          this.permTreeParams.parentId = this.userInfoObj.parentId;
        }
      },
    },
  },
  methods: {
    ...util,
    ...mapActions(["loadGroups"]),

    // 数据校验
    Validate() {
      const { type, parentUser } = this.inputParams;

      if (this.userInfoObj.name == "") {
        this.$message.error("登陆账户不能为空！");
        return false;
      }

      if (!phoneFun(this.userInfoObj.phone)) {
        this.$message.error("请输入正确的联系方式！");
        return false;
      }

      if (this.userInfoObj.password == "") {
        this.$message.error("密码不能为空！");
        return false;
      }
      if (
        this.userInfoObj.password.length < 6 ||
        this.userInfoObj.password.length > 20
      ) {
        this.$message.error("密码长度需在6-20位");
        return false;
      }

      if (this.userInfoObj.corpName == "") {
        this.$message.error("客户名称不能为空！");
        return false;
      }

      if (!(this.selectPermList && this.selectPermList.length)) {
        this.$message.warning("请选择用户权限");
        return false;
      }

      if (!type && !parentUser && this.userInfoObj.groupIds == "") {
        this.$message.error("请选择要绑定的车组！");
        return false;
      }

      return true;
    },

    //初始化用户信息
    initUserInfo() {
      this.userPermList = [];
      this.parentPermList = []; // //上级用户权限
      this.minPlayTime = 0; // //最小播放时长
      this.maxPlayTime = 60 * 24; // //最大播放时长
      this.dailyPlayTime = 0; //  //每日播放时长 分钟
      this.selectPermList = []; // 已选择的权限list
      return {
        name: "", // 用户名
        password: "", // 密码
        corpName: "", // 公司名称
        parentId: -1, // 用户父id
        parentName: "", //父级账户
        groupIds: "", // 绑定车组id
        accountType: 0, //  用户类型 1：我的客户 2：监控客户
        checkGroup: 0, // 是否有删除车组权限
        phone: "", // 联系电话
        customCode: "", // 客户代码
        uniqueCode: "", // 平台唯一代码
        businessLicense: "", // 经营许可证
        userId: 0, // 用户Id
        funcId: "", // 用户权限控制
        flag: 0, // 内部平台还是外部平台  0内部 1外部
        dailyPlayTime: 0, //每日播放时长 秒
        ip: "",
        idCard: "", // 身份证
        merchantName: "", // 业户名称
        roleId: "",
        identity: "", // 身份
        namestr: "",
        affiliate: "",
        industry: "",
        post: "",
        department: "",
        validity: "",
        appVideoPermission: 1,
      };
    },

    // 点击保存事件
    onSaveUser() {
      this.selectPermList = this.$refs.permTreeRef.getCheckeds(); // 获取选择节点

      //保存用户资料
      if (!this.Validate()) return;

      if (this.userInfoObj.validity) {
        this.userInfoObj.validity = moment(
          new Date(this.userInfoObj.validity)
        ).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.userInfoObj.validity = "";
      }
      this.userInfoObj.dailyPlayTime = this.dailyPlayTime * 60;
      this.userInfoObj.funcId = ""; //修改用户资料时，弃用权限字符funcId
      let arr = this.userPermList;
      this.vLoading = true;

      const { type } = this.inputParams;

      let result = !type
        ? addClientUser({
          ...this.userInfoObj,
          password: encrypt(this.userInfoObj.password),
          permissions: this.selectPermList.join(","),
        })
        : updateUser({
          user: {
            ...this.userInfoObj,
            password: encrypt(this.userInfoObj.password),
          },
          permissions: this.selectPermList.join(","),
        });
      result.then((res) => {
        this.vLoading = false;
        if (res.flag) {
          this.$message.success(res.msg);
          this.onModalChange(false, "on-ok", this.userInfoObj);
        } else if (res.msg) {
          this.$message.error(res.msg);
        }
      });
    },

    //根据用户id查询用户信息
    async getUser(userId) {
      let result = await getUserInfo({ userId });
      if (!(result.flag && result.obj)) {
        if (result.msg) this.$message.info(result.msg);
        return;
      }

      let { user, parentUser } = result.obj;
      Object.keys(user).forEach((key) => (this.userInfoObj[key] = user[key]));

      this.userInfoObj.parentName = parentUser.name;
      this.userInfoObj.password = decrypt(user.password);
      this.dailyPlayTime = Math.floor(user.dailyPlayTime / 60);
      if (parentUser.dailyPlayTime) {
        this.minPlayTime = 1;
        this.maxPlayTime = Math.floor(parentUser.dailyPlayTime / 60);
      }
    },

    showModal() {
      this.modalEditGroup = true;
    },
    onGenPwd() {
      this.userInfoObj.password = randomStr2(8);
    },
    onModalChange(modal, eventName = "on-cancel", ...options) {
      this.$emit("onModalChange", modal);
      this.$emit(eventName, ...options);
    },
    onModalCancel() {
      this.bindModal.userId = 0;
      this.bindModal.parentUser.userId = 0;
      this.bindModal.modal = false;
    },

    init() {
      this.editGroup = {
        groupName: "",
        groupId: -1,
        parentName: "",
        parentId: -1,
        phone: "",
        groupType: "客车",
        remark: "",
      };

      this.permTreeParams = {
        userId: null,
        parentId: null,
      };

      this.hasChoice = true;
    },
    showBindGroupModal(type) {
      this.bindModal.userId = 0;
      this.bindModal.parentUser.userId = this.userInfoObj.parentId;
      this.bindModal.modal = true;
    },

    bindVehicleBtn(val) {
      if (val.success) {
        this.num = val.data.length;
        this.hasChoice = false;
        let groupIdsItem = val.data.map((p) => p.groupId);
        this.userInfoObj.groupIds = groupIdsItem.join(",");
      }
      this.bindModal.modal = false;
    },

    // 校验IP
    checkIP(ips) {
      var isIP = true;
      var reg =
        /^((?:(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))))$/;
      var valdata = ips.ip.split(",");
      for (var i = 0; i < valdata.length; i++) {
        if (reg.test(valdata[i]) === false) {
          isIP = false;
          break;
        }
      }
      return isIP;
    },
    onOkCarGroup(data) {
      this.editGroup.groupId = data.groupId || -1;
      this.editGroup.parentId = data.parentId || -1;
      this.editGroup.parentName = data.parentName;
      this.editGroup.groupName = data.groupName;
      this.editGroup.phone = data.phone;
      this.editGroup.groupType = data.groupType;
      this.editGroup.remark = data.remark;
      this.loadGroups();
      this.num = 1;
      this.hasChoice = false;
      this.userInfoObj.groupIds = data.groupId;
    },
    onCancelCarGroup() {
      this.editGroup.groupId = -1;
      this.editGroup.parentId = -1;
      this.editGroup.parentName = "";
      this.modalEditGroup = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.modal-edit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit_content {
  width: 100%;
  height: 560px;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: center;
}
.content_right {
  width: 35%;
  border: 1px solid #dcdfe6;
  padding: 5px;
  overflow: auto;
}

.content_left {
  width: 65%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .edit-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
    label {
      width: 140px;
      text-align: end;
      padding-right: 10px;
    }
    .edit-item-body {
      width: calc(100% - 180px) !important;
      display: flex;
      align-items: center;
      .select {
        width: 100%;
        height: 35px;
        line-height: 35px;
        border-radius: 3px;
      }
    }
  }
}
.required::before {
  content: '*';
  color: red;
  font-weight: bold;
  margin-right: 1px;
  font-size: 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
  background: #212d3e;
  color: #fff;
  padding: 10px 20px;
  line-height: normal;
  .el-dialog__headerbtn {
    top: 10px;
  }
}

::v-deep .el-dialog__body {
  padding: 10px 10px;
}

::v-deep .el-dialog__footer {
  padding: 10px 20px;
}

// .edit-item-body-group {
//   border: 1px solid #ddd;
//   height: 32px;
//   padding-left: 15px;
//   line-height: 32px;
//   border-radius: 4px;
// }
</style>

