<template>
  <div class="UserTree" :style="userStyle">
    <!-- 虚拟滚动树 -->
    <VirtualTree
      ref="virtualTreeRef"
      :emptyText="emptyText"
      :data="userTreeData"
      :defaultExpandAll="defaultExpandAll"
      :showCheckbox="showCheckbox"
      :fieldNames="{
        key:'userId',
        parentKey:'parentId',
        label:'corpName'
      }"
      @node-click="onNodeClick"
      @check-change="onCheckChange"
    >
      <template v-slot="{node}">
        <div class="UserNode">
          <!-- 头像 -->
          <UserAvator></UserAvator>
          <!-- 用户名 -->
          {{node.corpName}}
          <!-- 操作 -->
          <label v-if="$scopedSlots.action">
            <slot name="action" :node="node"></slot>
          </label>
        </div>
      </template>
    </VirtualTree>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isObject } from 'lodash';
import VirtualTree from '@/components/VirtualTree';
import { getManageUserInfo } from '@/api/getManagementData.js';
import { arrayToTree } from '@/utils/treeHelper.js';
import UserAvator from './UserAvator.vue';
import { arrayTreeSort, genArrayTree } from '@/utils/treeHelper';
export default {
  name: 'UserTree',
  components: {
    VirtualTree,
    UserAvator,
  },
  props: {
    // 样式
    userStyle: {
      type: Object,
      default() {
        return {}
      }
    },

    userId: {
      type: Number,
      default: null,
    },
    // 是否默认展开父节点
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    // 是否在节点前添加复选框
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyText: '--',
      // 加载状态
      treeLoading: false,
      // 存储用户数据
      userList: [],
    };
  },
  computed: {
    ...mapState(['userInfo']),
    propUserId() {
      const { userId, userInfo } = this;
      return userId || userInfo.userId;
    },
    // 用户树
    userTreeData() {
      const { userList } = this;
      return arrayTreeSort(userList, null, { id: 'userId', parentId: 'parentId' });
    }
  },
  mounted() {
    this.loadUserTree();
  },
  methods: {

    // 滚动到可视区
    scrollIntoView(user) {
      const { virtualTreeRef } = this.$refs;
      let userId = user;
      if (isObject(user)) {
        userId = user.userId;
      }

      virtualTreeRef?.setCurrentKey(userId);
    },

    /**
     * 重新加载
     */
    reload(userId = null) {
      return this.loadUserTree(userId);
    },

    /**
     * 加载用户树
     * userId: 默认当前登录用的`userId`
     */
    async loadUserTree(userId) {
      userId = this.propUserId;

      this.userList = [];
      this.treeLoading = true;
      this.setEmptyText();

      try {
        const result = await getManageUserInfo({ userId });

        this.setEmptyText(result?.msg);

        if (result.flag !== 1) return;

        // 成功
        const { userList } = result.obj;

        this.userList = userList;

      } catch (err) {
        console.error(err);
      } finally {
        this.treeLoading = false;
      }
    },

    // 设置无数据的显示文本
    setEmptyText(text = '--') {
      this.emptyText = text;
    },

    // 节点点击事件
    onNodeClick(node) {
      this.$emit('nodeClick', node);
    },
    // 节点勾选事件
    onCheckChange(node, checked) {
      this.$emit('checkChange', node, checked);
    }

  }
}
</script>

<style lang="scss" scoped>
.UserTree {
  position: relative;
  height: 100%;
}
.UserNode {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 8px;
}
</style>
