import GBK from '@/libs/gbk.min.js';

export const setCookie = (name, value = "", time = "h2") => {
   if (!name) return;
   let strsec = getsec(time);
   let exp = new Date();
   exp.setTime(exp.getTime() + strsec * 1);
   document.cookie = `__bysk.${ name }=${ escape(
      value
   ) };expires=${ exp.toGMTString() }`;
};

export const setCookieTwo = (name, value = "", time = "h2") => {
   let strsec = getsec(time);
   let exp = new Date();
   exp.setTime(exp.getTime() + strsec * 1);
   document.cookie = `__bysk.${ name }=${ escape(
      value
   ) };expires=${ exp.toGMTString() };domain=car900.com`;
};

export const getCookie = name => {
   if (!name) return;
   name = `__bysk.${ name }`;
   let arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
   if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
   else return null;
};

/**
 * 格式化时间
 */
export const formatDuring = mss => {
   let days = parseInt(mss / (1000 * 60 * 60 * 24));
   let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
   let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
   let seconds = parseInt(mss % (1000 * 60)) / 1000;
   if (mss >= 86400000) {
      return days + "天" + hours + "小时" + minutes + "分" + seconds + "秒";
   }
   if (mss >= 3600000) {
      return hours + "小时" + minutes + "分" + seconds + "秒";
   }
   if (mss >= 60000) {
      return minutes + "分" + seconds + "秒";
   }
   if (mss >= 1000) {
      return seconds + "秒";
   }
   return "";
};

// Date增加Add方法
export const dateAdd = function (count, dd) {
   if (dd == undefined) {
      dd = new Date();
   }
   dd.setDate(dd.getDate() + count);
   return dd.format("yyyy-MM-dd");
};
export const dateAdd1 = function (count, dd) {
   if (dd == undefined) {
      dd = new Date();
   }
   dd.setDate(dd.getDate() + count);
   return dd.format("yyyy-MM-dd HH:mm:ss");
};

/**
 * 在指定的时间内
 * @param {*} fn
 * @param {*} ms
 * @param  {...any} options
 */
export const onceFn = (fn, ms, ...options) => {
   if (!window.abcsdwswwss) window.abcsdwswwss = {};
   if (window.abcsdwswwss[fn]) return;
   if (ms > 0)
      window.abcsdwswwss[fn] = delay(() => delete window.abcsdwswwss[fn], ms);
   fn(...options);
};

export const delay = (func, ms, ...options) => {
   if (ms === -1) {
      func(...options);
      return -1;
   }
   let tth = setTimeout(() => {
      clearTimeout(tth);
      func(...options);
   }, ms || 0);
   return tth;
};

export const asleep = (ms) => {
   return new Promise((resolve) => {
      delay(resolve, ms);
   });
};

// export const setTimer = (fn, ms, ...options) => {
//   let tid = setTimeout(() => {
//     fn(...options);
//     setTimer(fn, ms, ...options);
//   }, ms)
// }
export const randomStr = (notNum = 0) => {
   let str = Math.random()
      .toString(36)
      .substr(2);
   if (notNum) return str.replace(/\d/g, "");
   return str;
};

export const randomStr2 = (len, onlyLetter = false) => {
   let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
   str += "abcdefghijklmnopqrstuvwxyz";
   if (!onlyLetter) {
      str += "0123456789-_!";
   }
   let tstr = "";
   for (let i = 0; i < len; ++i) {
      tstr += str[Math.floor(Math.random() * str.length)];
   }
   return tstr;
};

/**
 * 随机生成指定范围的数
 * @param {*} min
 * @param {*} max
 */
export const randomNumber = (min, max) => Math.random() * (max - min) + min;

/**
 * 随机生成指定范围的整数
 * @param {*} min
 * @param {*} max
 */
export const randomInt = (min, max) =>
   Math.floor(Math.random() * (max - min + 1)) + min;

export const getsec = function (str) {
   let str1 = str.substring(1, str.length) * 1;
   let str2 = str.substring(0, 1);
   if (str2 == "s") {
      return str1 * 1000;
   } else if (str2 == "h") {
      return str1 * 60 * 60 * 1000;
   } else if (str2 == "d") {
      return str1 * 24 * 60 * 60 * 1000;
   }
};

export const UTC = {
   unixToDate: function (unixDate) {
      if (!unixDate) return new Date();
      return new Date(unixDate * 1000);
   },
   getDateUnix: function (inDate) {
      if (inDate == undefined) inDate = new Date();
      return Math.round(inDate.getTime() / 1000);
   }
};

/**
 * 像素值转为数字，如：100px=>100
 * @param {*} pixel
 */
export const pixel2Num = pixel => {
   if (typeof pixel === "string" && pixel.endsWith("px")) {
      return pixel.substr(0, pixel.length - 2) * 1;
   }
   return pixel * 1;
};


export let throttle = (function (immediate) {
   let timer = null;
   let callNow = immediate;

   return function (fn, wait) {
      let context = this,
         args = arguments;

      if (callNow) {
         fn.apply(context, args);
         callNow = false;
      }

      if (!timer) {
         timer = setTimeout(() => {
            fn.apply(context, args);
            timer = null;
         }, wait);
      }
   };
})();



/**
 * 是否是中文字符
 * @param {*} char
 */
export const isZHChar = char => /^[\u4e00-\u9fa5]$/.test(char);

/**
 * 进制转换
 * @param {*} obj 
 */
export const conversionHex = (obj) => {
   let str = '';
   for (let key in obj) {
      if (obj[key].type == 'BYTE') {
         str += parseInt(obj[key].value).toString(16).padStart(2, '0');
      }
      if (obj[key].type == 'DWORD') {
         str += parseInt(obj[key].value).toString(16).padStart(8, '0');
      }
      if (obj[key].type == 'WORD') {
         str += parseInt(obj[key].value).toString(16).padStart(4, '0');
      }
      if (obj[key].type == 'BCD') {
         str += obj[key].value.toString().padStart(12, '0');
      }
      if (obj[key].type == 'STRING') {
         let arr = GBK.encode(obj[key].value).map(n => n.toString(16)).join('');
         str += arr;
      }
      if (obj[key].type == 'BIN') {
         str += obj[key].value.toString();
      }
      if (!obj[key].hasOwnProperty('type')) {
         str += obj[key].value;
      }
   }
   return str;
};

/**
 * 判断对象是否是符合JSON的对象
 * @param {*} obj 
 */
export const isObject = (obj) => typeof (obj) == "object" && Object.prototype.toString.call(obj).toLowerCase() == "[object object]";


/**
 * 对象转为URL查询参数
 * @param {*} obj 
 */
export const toURLParams = (obj, headers) => {

   //FIXME: isObject 有问题，需要验证
   if (!isObject(obj)) return obj;
   if (isObject(obj) &&
      headers &&
      headers['Content-Type'] &&
      !headers['Content-Type'].includes('x-www-form-urlencoded')
      // && headers['Content-Type'].includes('json')
   ) {
      return JSON.stringify(obj);
   }

   return Object.entries(obj).map(([key, val]) => `${ encodeURIComponent(key) }=${ encodeURIComponent(typeof (val || '') === 'object' ? JSON.stringify(val) : val) }`).join('&');
};

export const isEmpty = (obj) => (obj === undefined || obj === null || obj === '');

/**
 * 
 * @param {*} cb 返回检测对象
 * @param {*} timeout 超时时间
 * @param {*} ms 
 */
export const isEmptyAsync = (cb, timeout = 500, ms = 100) => {
   return new Promise((reslove) => {
      if (!isEmpty(cb())) return reslove(true);
      let time = 0;
      let timer = setInterval(() => {
         time += ms;
         let flag = !isEmpty(cb());
         if (flag || time >= timeout) {
            clearInterval(timer);
            timer = null;
            return reslove(!flag);
         }
      }, ms);
   });
};


/**
 * Blob文件转ArrayBuffer
 * @param {*} blob 
 */
export const toArrayBuffer = (blob) => {
   return new Promise(resolve => {
      try {
         const reader = new FileReader();
         reader.readAsArrayBuffer(blob);
         reader.onload = () => resolve(reader.result);
      } catch (err) {
         //
         resolve(null);
      }
   });
};

/*
 * 语音TTS
 * @param {*} text 
 * @param {*} opts 
 */
export const speechSpeak = (text, opts = {}) => {
   const {
      pitch,
      rate,
      volume,
      lang
   } = opts;
   const utter = new SpeechSynthesisUtterance();
   utter.pitch = pitch || 1; //音调
   utter.rate = rate || 1; //速度
   utter.volume = volume || 1; //音量
   utter.lang = lang || "zh-CN"; //语言
   utter.text = text;
   console.log(text);
   speechSynthesis.speak(utter);
   utter.onstart = () => { };
   utter.onend = () => { };
};

/**
 * 移除所有语音谈话队列中的谈话。
 */
export const cancelSpeechSpeek = () => speechSynthesis.cancel();

/**
 * 字符串排序 首字母
 * 数字<英文<中文
 * 不支持混合
 * @param {*} str1 
 * @param {*} str2 
 */
export const stringSort = (istr1, istr2) => {
   let str1 = istr1 == null ? '' : istr1.toString().trim();
   let str2 = istr2 == null ? '' : istr2.toString().trim();

   const compare = (a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
   };

   // 判断数字
   const isNum = (regstr) => /^[0-9]*$/.test(regstr);
   // 判断英文
   const isEn = (regstr) => /^[a-zA-Z]*$/.test(regstr);
   // 判断除数字,英文,中文的其他字符
   // const isSpec = (regstr) => !(isZHChar(regstr) || /[0-9a-zA-Z]/.test(regstr));

   if (!(str1 && str2)) return compare(str1, str2); // 空字符排最前面


   // 都是数字或都是英文 Unicode 编码比较字
   if ((isNum(str1) && isNum(str2)) ||
      (isEn(str1) && isEn(str2))) return compare(str1, str2);
   if (isNum(str1) && !isNum(str2)) return -1; // str1是数字, str2不是数字, 数字在前
   if (!isNum(str1) && isNum(str2)) return 1; // str1不是数字, str2是数字, 数字在前
   if (isEn(str1) && !isEn(str2)) return -1; // str1是英文, str2不是数字和英文, 英文在前
   if (!isEn(str1) && isEn(str2)) return 1; // str2是英文, str1不是数字和英文, 英文在前

   // 比较首字母
   let letter1 = str1.charAt(0);
   let letter2 = str2.charAt(0);

   if ((isNum(letter1) && isNum(letter2)) ||
      (isEn(letter1) && isEn(letter2))) return compare(letter1, letter2);
   if (isNum(letter1) && !isNum(letter2)) return -1; // str1是数字, str2不是数字, 数字在前
   if (!isNum(letter1) && isNum(letter2)) return 1; // str1不是数字, str2是数字, 数字在前
   if (isEn(letter1) && !isEn(letter2)) return -1; // str1是英文, str2不是数字和英文, 英文在前
   if (!isEn(letter1) && isEn(letter2)) return 1; // str2是英文, str1不是数字和英文, 英文在前

   // 首字母都是中文
   if (isZHChar(str1.charAt(0)) && isZHChar(str2.charAt(0))) return str1.localeCompare(str2, 'zh');
   if (isZHChar(str1.charAt(0))) return -1; // str1首字母中文
   if (isZHChar(str2.charAt(0))) return 1; // str2首字母中文在前

   return 0;

};

/**
 * 新建browser标签页
 * @param {*} href 
 * @param {*} param1 
 */
export const openNewTab = (href, {
   target,
   rel
} = {}) => {
   const a = document.createElement('a');
   a.href = href;
   a.target = target || '_blank';
   a.rel = rel || 'noopener noreferrer';
   a.click();
};
