<template>
  <img class="avatorImg" :src="avatorImg" />
</template>

<script>
export default {
  name: 'UserAvator',
  computed: {
    avatorImg() {
      return require('@/assets/images/用户树头像.png');
    }
  }
}
</script>

<style lang="scss" scoped>
.avatorImg {
  position: absolute;
  left: 0;
  width: 12px;
  height: 12px;
}
</style>