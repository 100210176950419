<template>
  <div :style="outerStyle"
    :class="[
    'SplitTrigger', 
     isNormal && isColumn && 'cursor-row',
     isNormal && !isColumn && 'cursor-col'
    ]"
    @mousedown.stop="onMoveStart">
    <FoldButton first
      v-show="showArrow1 && showButton"
      class="ShowArrow"
      :expandSide="expandSide1"
      :tranType="tranType"
      @click="onArrowClick1"></FoldButton>
    <FoldButton second
      v-show="showArrow2 && includeMaximize"
      class="ShowArrow"
      :expandSide="expandSide2"
      :tranType="tranType"
      @click="onArrowClick2"></FoldButton>
  </div>
</template>

<script>

/**
 * 分隔面板中间的分隔区域
 */

import FoldButton from '@/components/FoldButton.vue';

const FlexDriecton = {
  Row: 'row',
  RowReverse: 'row-reverse',
  Column: 'column',
  ColumnReverse: 'column-reverse',
};

export default {
  name: 'SplitTrigger',
  components: {
    FoldButton,
  },
  model: {
    prop: 'offsetPtg',
  },
  props: {
    offsetPtg: {
      type: Number,
      default: 0.2,
    },
    // 默认百分比
    defaultOffsetPtg: {
      type: Number,
      default: 0.2,
    },
    triggerWidth: {
      type: Number,
      default: 10,
    },
    direction: {
      type: String,
      default: 'row',
      validator(val) {
        return Object.values(FlexDriecton).includes(val);
      }
    },
    // 是否有最大化
    includeMaximize: {
      type: Boolean,
      default: false,
    },
    // 拖动最小阈值
    min: {
      type: [Number, String],
      default: '40px',
    },
    // 拖动最大阈值
    max: {
      type: [Number, String],
      default: 0.9,
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.dragObj = {
      moving: false, // 是否正在移动
      startPos: 0, // 鼠标按下开始位置
      wrapperSize: 0, // 容器大小
      startOffsetPtg: 0, // 开始占比
    };
    return {
      // 偏移百分比
      innerOffsetPtg: this.offsetPtg,
    };
  },
  computed: {
    isColumn() {
      const { direction } = this;
      return ['column', 'column-reverse'].includes(direction);
    },
    showArrow1() {
      const { direction, innerOffsetPtg } = this;

      if (direction === FlexDriecton.Row
        || direction === FlexDriecton.RowReverse) {
        return innerOffsetPtg !== 1;
      }
      return innerOffsetPtg !== 0;
    },
    showArrow2() {
      const { direction, innerOffsetPtg } = this;
      if (direction === FlexDriecton.Column
        || direction === FlexDriecton.ColumnReverse) {
        return innerOffsetPtg !== 1;
      }
      return innerOffsetPtg !== 0;
    },
    isNormal() {
      const { innerOffsetPtg } = this;
      return innerOffsetPtg > 0 && innerOffsetPtg < 1;
    },

    // 箭头1指向方向, 左/右 or上/下
    expandSide1() {
      const { direction, isNormal } = this;

      if (isNormal) {
        if (direction === FlexDriecton.Row
          || direction === FlexDriecton.Column) {
          return true;
        }
        return false;
      }

      if (direction === FlexDriecton.Row
        || direction === FlexDriecton.Column) {
        return false;
      }

      return true;

    },
    // 箭头2指向方向, 左/右 or上/下
    expandSide2() {
      const { direction, isNormal } = this;

      if (isNormal) {
        if (direction === FlexDriecton.Row
          || direction === FlexDriecton.Column) {
          return false;
        }
        return true;
      }

      if (direction === FlexDriecton.RowReverse
        || direction === FlexDriecton.ColumnReverse) {
        return false;
      }

      return true;

    },
    outerStyle() {
      const { direction, innerOffsetPtg, isNormal, triggerWidth } = this;

      const style = {};

      if (direction === FlexDriecton.Row
        || direction === FlexDriecton.RowReverse) {
        // 水平布局
        style['width'] = `${ triggerWidth }px`;
        style['height'] = '100%';
        style['flex-direction'] = 'column';
      } else if (direction === FlexDriecton.Column
        || direction === FlexDriecton.ColumnReverse) {
        // 垂直布局
        style['width'] = '100%';
        style['height'] = `${ triggerWidth }px`;
        style['flex-direction'] = 'row';
      }

      // 停靠在边框
      if (!isNormal) {
        style['position'] = 'absolute';
        if (innerOffsetPtg === 0) {
          if (direction === FlexDriecton.Row) {
            // 分隔栏停靠在左边
            style['left'] = 0;
          } else if (direction === FlexDriecton.RowReverse) {
            // 分隔栏停靠在右边
            style['right'] = 0;
          } else if (direction == FlexDriecton.Column) {
            // 分隔栏停靠在顶部
            style['top'] = 0;
          } else if (direction == FlexDriecton.ColumnReverse) {
            // 分隔栏停靠在底部
            style['bottom'] = 0;
          }
        } else {
          if (direction === FlexDriecton.Row) {
            // 分隔栏停靠在右边
            style['right'] = 0;
          } else if (direction === FlexDriecton.RowReverse) {
            // 分隔栏停靠在左边
            style['left'] = 0;
          } else if (direction == FlexDriecton.Column) {
            // 分隔栏停靠在底部
            style['bottom'] = 0;
          } else if (direction == FlexDriecton.ColumnReverse) {
            // 分隔栏停靠在顶部
            style['top'] = 0;
          }
        }
      }
      return style;
    },
    // 控制箭头水平垂直方向
    tranType() {
      const { direction } = this;
      if (direction === FlexDriecton.Row
        || direction === FlexDriecton.RowReverse) {
        return 'aside';
      }
      return 'upDown';
    },
  },
  watch: {
    offsetPtg(val) {
      if (val < 0 || val > 1) throw new Error('越界[0,1] ' + val);
      this.innerOffsetPtg = val;
    }
  },
  beforeMount() {
    this.onMoveStart = this.onMoveStart.bind(this);
    // this.onMoving = throttle(this.onMoving, 100).bind(this);
  },
  mounted() {
  },
  methods: {

    getParentEl() {
      return this.$el?.parentElement;
    },

    refresh() {

    },

    setOffsetPtg(offsetPtg) {
      if (offsetPtg < 0 || offsetPtg > 1) return;
      offsetPtg = parseFloat(offsetPtg.toFixed(6));

      this.innerOffsetPtg = offsetPtg;
      this.$emit('input', offsetPtg);
      this.$emit('trigger', offsetPtg);
    },
    onArrowClick1() {
      const { direction, isNormal, defaultOffsetPtg } = this;
      console.log("isNormal", isNormal);
      if (!isNormal) { // 正常化
        this.setOffsetPtg(defaultOffsetPtg);
      } else {
        if (direction === FlexDriecton.Column
          || direction === FlexDriecton.ColumnReverse) {
          this.setOffsetPtg(1);
        } else {
          this.setOffsetPtg(0);
        }
      }
    },
    onArrowClick2() {
      const { direction, isNormal, defaultOffsetPtg } = this;
      if (!isNormal) { // 正常化
        this.setOffsetPtg(defaultOffsetPtg);
      } else {
        if (direction === FlexDriecton.Column
          || direction === FlexDriecton.ColumnReverse) {
          this.setOffsetPtg(0);
        } else {
          this.setOffsetPtg(1);
        }
      }
    },
    getMaxOffset() {
      const { isColumn } = this;
      const parentEl = this.getParentEl();
      if (!parentEl) return Number.MAX_VALUE;
      const { width, height } = parentEl?.getBoundingClientRect();
      return (isColumn ? height : width);
    },
    onMoveStart(e) {

      const { isColumn, isNormal, dragObj, innerOffsetPtg } = this;

      // 停靠在边框时, 不让拖动
      if (!isNormal) return;

      this.$emit('moveStart', e);

      const maxOffset = this.getMaxOffset();

      dragObj.moving = true;
      dragObj.startPos = isColumn ? e.pageY : e.pageX;
      dragObj.wrapperSize = maxOffset;
      dragObj.startOffsetPtg = innerOffsetPtg;

      window.addEventListener('mousemove', this.onMoving);
      window.addEventListener('mouseup', this.onMovingEnd);
      window.addEventListener('contextmenu', this.onMovingEnd);

      document.body.style.cursor = !isColumn
        ? 'col-resize'
        : 'row-resize';
    },
    // 移动结束，解除事件绑定
    onMovingEnd(e) {
      this.dragObj.moving = false;

      window.removeEventListener('mousemove', this.onMoving);
      window.removeEventListener('mouseup', this.onMovingEnd);
      window.removeEventListener('contextmenu', this.onMovingEnd);

      document.body.style.cursor = 'default';
      this.$emit('moveEnd', e);
    },
    // 移动中，更新 firstPane 的占位大小
    onMoving(e) {
      const { direction, isColumn, dragObj } = this;
      if (!dragObj.moving) return;

      this.$emit('moving', e);

      const startPosition = dragObj.startPos;
      const endPosition = isColumn ? e.pageY : e.pageX;

      let newOffsetPtg = 0;

      if (direction == FlexDriecton.Row
        || direction === FlexDriecton.Column) {
        newOffsetPtg = this.getNewPtg(
          dragObj.startOffsetPtg, // 开始占比
          endPosition - startPosition,
          dragObj.wrapperSize, // 容器宽度
        );
      } else if (direction === FlexDriecton.RowReverse
        || direction === FlexDriecton.ColumnReverse) {
        newOffsetPtg = this.getNewPtg(
          dragObj.startOffsetPtg, // 开始占比
          -(endPosition - startPosition),
          dragObj.wrapperSize, // 容器宽度
        );
      }


      const minPtg = this.getMinPtg();
      const maxPtg = this.getMaxPtg();

      newOffsetPtg = Math.max(minPtg, newOffsetPtg);
      newOffsetPtg = Math.min(maxPtg, newOffsetPtg);

      this.setOffsetPtg(newOffsetPtg);
    },
    getNewPtg(startOffsetPtg, movingDistance, wrapperSize) {
      const offset = parseFloat(wrapperSize * startOffsetPtg + movingDistance);
      const ptg = offset / parseFloat(wrapperSize);
      return ptg;
    },
    getMinPtg() {
      const { min, dragObj } = this;
      let minPtg = parseFloat(min);
      if (minPtg > 1) {
        minPtg = parseFloat((minPtg / dragObj.wrapperSize).toFixed(6));
      }
      return minPtg;
    },
    getMaxPtg() {
      const { max, dragObj } = this;
      let maxPtg = parseFloat(max);
      if (maxPtg > 1) {
        maxPtg = parseFloat((maxPtg / dragObj.wrapperSize).toFixed(6));
      }
      return maxPtg;
    }
  }
}
</script>

<style lang="scss" scoped>
.SplitTrigger {
  // position: absolute;
  background: transparent;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.cursor-col:hover {
  cursor: ew-resize;
}
.cursor-row:hover {
  cursor: ns-resize;
}
</style>